import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Box, Checkbox } from "@mui/material";
import { Button, Grid, FormControl, TextareaAutosize } from "@mui/material";
import { InputAdornment, LinearProgress, TextField } from "@mui/material";
import { styled, Stack, Tab, Tabs, Typography } from "@mui/material";
import Repo from "../../../repo/Repo";
import Toast from "../../../components/Toast";
import LimitWarning from "../../create chatbot/LimitWarning";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import { getChatbot } from "../chatbotSlice";
import { useLocation } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ mt: 3 }}>{children}</Box>}
    </div>
  );
}

const TitleBox = styled(Box)({
  width: "88%",
  background: "rgba(255, 255, 255, 0.05)",
  borderRadius: "6px",
  display: "flex",
  padding: "10px",
});
const AddBox = styled(Box)({
  marginLeft: 2,
  width: "100%",
  height: "100%",
  background: "rgba(255, 255, 255, 0.1)",
  borderRadius: "6px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
});

function Sources() {
  const botId = localStorage.getItem("botId");
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const location = useLocation();
  const { chatbot } = useSelector((state) => state.chatbot);
  const [value, setValue] = useState(0);
  const [siteUrl, setSiteUrl] = useState("");
  const [fetchUrls, setFetchUrls] = useState([]);
  const [linksProgress, setLinksProgress] = useState(1);
  const [progress, setProgress] = useState(1);
  const [linksLoading, setLinksLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [limitWarning, setLimitWarning] = useState({
    modal: false,
    currentChars: "",
    leftChars: "",
  });
  const [newFiles, setNewFiles] = useState([]);
  const [oldFiles, setOldFiles] = useState([]);
  const [uploadFile, setUploadFile] = useState("");
  const [text, setText] = useState("");

  const handleOpen = () => {
    inputRef.current.click();
  };
  const found = selected?.reduce((acc, item) => {
    acc[item] = item;
    return acc;
  }, {});

  useLayoutEffect(() => {
    let items = chatbot?.urls?.split(",");
    setSelected(items);
    if (chatbot?.text) {
      setText(chatbot.text);
    }
  }, []);
  useLayoutEffect(() => {
    if (chatbot?.s3Files) {
      setOldFiles(chatbot.s3Files);
    }
  }, [chatbot.s3Files]);

  useEffect(() => {
    let id = botId ?? location?.state?.botId;
    if (!chatbot) {
      dispatch(getChatbot(id));
    }
    localStorage.setItem("lastVisited", location.pathname);
    localStorage.setItem("botId", id);

    return () => {
      localStorage.removeItem("lastVisited");
      localStorage.removeItem("botId");
    };
  }, []);

  const handleInput = (e) => {
    const { value } = e.target;
    setSiteUrl(value);
  };
  const handleFile = (e) => {
    if (loading) {
      return;
    }
    const { files } = e.target;

    if (files) {
      setUploadFile(files[0]);
    } else {
      setUploadFile("");
    }
  };
  const handleSelected = () => {
    if (loading) {
      return;
    }
    setNewFiles((prev) => [uploadFile, ...prev]);
    setUploadFile("");
  };

  const handleDeleteNew = (index) => {
    if (loading) {
      return;
    }
    let copy = [...newFiles];
    copy.splice(index, 1);
    setNewFiles(copy);
  };

  const handleFetchLinks = async () => {
    setFetchUrls([]);
    setLinksLoading(true);
    const linksTimer = setInterval(() => {
      setLinksProgress((prev) => (prev >= 100 ? 1 : prev + 1));
    }, 500);
    try {
      let { data } = await Repo.chatbot({
        request: {
          method: "getAllUrls",
          data: {
            url: siteUrl,
          },
        },
      });
      let res = data.response.data.scrappedUrls;
      clearInterval(linksTimer);
      setLinksProgress(1);
      if (res.length > 0) {
        setLinksLoading(false);
        setFetchUrls(res);
      } else {
        setLinksLoading(false);
        Toast("info", "No links found");
      }
    } catch (err) {
      setLinksProgress(1);
      setLinksLoading(false);
      Toast("error", err);
    }
  };

  const filtered = () => {
    if (chatbot?.urls) {
      let items = chatbot?.urls?.split(",");
      return items;
    } else {
      return [];
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleSelect = (item) => {
    let found = selected.find((i) => i == item);
    if (found) {
      let filtered = selected.filter((i) => i != item);
      setSelected(filtered);
    } else {
      if (selected.length >= 10) {
        Toast("info", "Only 10 links are allowed");
      } else {
        setSelected((prev) => [item, ...prev]);
      }
    }
  };

  const warnToggle = () => {
    setLimitWarning({
      modal: false,
      currentChars: "",
      leftChars: "",
    });
  };
  const handleRetrain = async (arg, rem) => {
    setLoading(true);
    const linksTimer = setInterval(() => {
      setProgress((prev) => (prev >= 100 ? 1 : prev + 1));
    }, 500);
    let myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer V78L3fNtjQ7quJ94h8YVSg==1VYOuAp34mHUAbPp"
    );

    let formdata = new FormData();
    if (newFiles.length > 0) {
      newFiles.forEach((item) => {
        formdata.append("files", item);
      });
    } else {
      formdata.append("files", "");
    }

    if (oldFiles?.length > 0) {
      oldFiles.forEach((item) => {
        formdata.append("existingFiles", item);
      });
    }
    formdata.append("email", chatbot.email);
    formdata.append("botName", chatbot.botName);
    formdata.append("urls", selected);
    formdata.append("text", text);
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch("https://api.urhja.com/update_chatbot", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let res = JSON.parse(result);
        clearInterval(linksTimer);
        setLoading(false);
        setLinksProgress(1);
        if (res?.chatBotId) {
          Toast("success", "Chatbot Retrained Successfully");
          dispatch(getChatbot(chatbot._id));
          setNewFiles([]);
        } else {
          if (res.error == "Not Created:Character limit exceeds!") {
            setLimitWarning({
              modal: true,
              currentChars: res?.characters,
              leftChars: res?.charactersLeft,
            });
          } else {
            Toast("error", res.error);
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
        Toast("error", error);
        setLoading(false);
        setLinksProgress(1);
        clearInterval(linksTimer);
      });
  };

  const handleDelete = (index) => {
    if (loading) {
      return;
    }
    let copy = [...oldFiles];
    copy.splice(index, 1);
    setOldFiles(copy);
  };
  const handleText = (e) => {
    const { value } = e.target;
    setText(value);
  };

  return (
    <Box sx={{ minHeight: "50vh" }}>
      <Box sx={{ margin: "0 auto", width: { xs: "100%", md: "50%" } }}>
        <Tabs
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.05)",
            borderRadius: "6px",
          }}
          textColor="rgba(53, 53, 53, 1)"
          variant="standard"
          value={value}
          onChange={handleChange}
          centered
        >
          <Tab
            label="Files"
            sx={{
              fontFamily: "Raleway",
              fontWeight: 600,
              fontSize: "10.5px",
            }}
          />
          <Tab
            label="Text"
            sx={{
              fontFamily: "Raleway",
              fontWeight: 600,
              fontSize: "10.5px",
            }}
          />
          <Tab
            label="Website"
            sx={{
              fontFamily: "Raleway",
              fontWeight: 600,
              fontSize: "10.5px",
            }}
          />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Grid container>
            {uploadFile && (
              <Typography sx={{ textAlign: "center" }}>
                Please click on "+" icon to add your file once you have uploaded
                it
              </Typography>
            )}
            <Grid item xs={10.5}>
              <Stack width="100%">
                <FormControl fullWidth>
                  <TextField
                    inputRef={inputRef}
                    fullWidth
                    variant="outlined"
                    size="small"
                    type="file"
                    sx={{
                      height: "40px",
                      borderRadius: "4px",
                      background: "rgba(255, 255, 255, 0.05)",
                      border: "none",
                      "& fieldset": { padding: "0px", border: "none" },
                    }}
                    autoComplete="off"
                    InputProps={{
                      style: {
                        padding: "0",
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <Button
                            variant="contained"
                            sx={{
                              textTransform: "none",
                              background: "rgba(53, 53, 53, 1) !important",
                            }}
                            onClick={handleOpen}
                          >
                            Choose File
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                    onChange={handleFile}
                  />
                </FormControl>
              </Stack>
            </Grid>

            {uploadFile && (
              <Grid item xs={1.2}>
                <AddBox onClick={handleSelected}>
                  <AddCircleOutlineIcon />
                </AddBox>
              </Grid>
            )}
          </Grid>

          {newFiles?.length > 0 && <Typography mt={2}>New Uploaded</Typography>}

          <Stack>
            {newFiles?.map((item, i) => {
              return (
                <Stack
                  mb={0.5}
                  key={i}
                  width="100%"
                  direction="row"
                  justifyContent="space-between"
                >
                  <TitleBox>
                    <Typography>{item?.name}</Typography>
                  </TitleBox>
                  <Box
                    sx={{
                      width: "9.5%",
                      background: "rgba(255, 255, 255, 0.1)",
                      borderRadius: "6px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <DeleteIcon
                      sx={{
                        color: "rgba(203, 76, 76, 1)",
                        marginX: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleDeleteNew(i)}
                    />
                  </Box>
                </Stack>
              );
            })}
          </Stack>

          {oldFiles?.length > 0 && (
            <Typography mt={2}>Uploaded Files</Typography>
          )}
          <Stack>
            {oldFiles?.map((item, i) => {
              const pattern = /\/(\d*)([^\/]+\.pdf)$/;
              const match = item.match(pattern);
              let filename = "";
              if (match) {
                filename = match[2];
              }
              return (
                <Stack
                  mb={0.5}
                  key={i}
                  width="100%"
                  direction="row"
                  justifyContent="space-between"
                >
                  <TitleBox>
                    <Typography>{filename}</Typography>
                  </TitleBox>
                  <Box
                    sx={{
                      width: "9.5%",
                      background: "rgba(255, 255, 255, 0.1)",
                      borderRadius: "6px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <DeleteIcon
                      sx={{
                        color: "rgba(203, 76, 76, 1)",
                        marginX: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleDelete(i)}
                    />
                  </Box>
                </Stack>
              );
            })}
          </Stack>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TextareaAutosize
            placeholder="Write text here ..."
            value={text}
            onChange={handleText}
            style={{
              background: "rgba(255, 255, 255, 0.05)",
              borderRadius: "6px",
              padding: "10px",
              height: 230,
              border: 0,
              width: "100%",
              boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
              overflow: "auto",
            }}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Typography
            sx={{ fontFamily: "Raleway", fontWeight: 500, fontSize: "14px" }}
          >
            Fetch Website Links
          </Typography>
          <Stack width="100%" spacing={1}>
            <TextField
              fullWidth
              size="small"
              placeholder="https://www.example.com"
              sx={{
                background: "rgba(255, 255, 255, 0.05)",
                borderRadius: "6px",
                color: "rgba(53, 53, 53, 0.5)",
                border: "none",
                "& fieldset": { border: "none" },
              }}
              onKeyDown={(e) => {
                if (e.key == "Enter") {
                  handleFetchLinks();
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      variant="contained"
                      sx={{
                        background: "rgba(53, 53, 53, 1) !important",
                        color: "#FFFFFF",
                        borderRadius: "6px",
                        textTransform: "none",
                        fontSize: { xs: "10px", md: "14px" },
                      }}
                      onClick={handleFetchLinks}
                    >
                      Fetch Links
                    </Button>
                  </InputAdornment>
                ),
              }}
              value={siteUrl}
              onChange={handleInput}
            />

            <Box sx={{ height: "10px", mb: 2, width: "100%" }}>
              {linksLoading && (
                <>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                  >{`${Math.round(linksProgress)}%`}</Typography>
                  <Box
                    sx={{
                      height: "6px",
                      background: "rgba(242, 242, 242, 0.2)",
                    }}
                  >
                    <LinearProgress
                      sx={{ height: "6px" }}
                      variant="determinate"
                      value={linksProgress}
                    />
                  </Box>
                </>
              )}
            </Box>
          </Stack>

          <Stack width="100%" maxHeight="350px" overflow="auto" spacing={0.5}>
            {fetchUrls &&
              fetchUrls.map((item, i) => {
                return (
                  <Box key={i} sx={{ display: "flex" }}>
                    <Checkbox
                      disabled={loading ? true : false}
                      checked={found[item] ? true : false}
                      onChange={() => handleSelect(item)}
                      inputProps={{ "aria-label": "controlled" }}
                      sx={{
                        "&.Mui-checked": {
                          color: "white",
                        },
                      }}
                    />
                    <Box
                      sx={{
                        background: "rgba(255, 255, 255, 0.05)",
                        borderRadius: "6px",
                        p: "5px",
                        pt: "10px",
                        width: "89%",
                        display: "block",
                        alignItems: "center",
                        wordWrap: "break-word",
                        cursor: "pointer",
                      }}
                      onClick={() => handleSelect(item)}
                    >
                      <Typography>{item}</Typography>
                    </Box>
                  </Box>
                );
              })}
          </Stack>
          {filtered().length == 0 ? (
            <Typography my={2} sx={{ textAlign: "center" }}>
              No URLs
            </Typography>
          ) : (
            <Typography
              sx={{ mt: 3, mb: 1, fontFamily: "Raleway", fontSize: "14px" }}
            >
              Included Links
            </Typography>
          )}

          {filtered().length > 0 && (
            <Box
              sx={{
                borderRadius: "6px",
                padding: "4px",
                background: "rgba(255, 255, 255, 0.1)",
              }}
            >
              {filtered().map((item, i) => {
                return (
                  <Box key={i} sx={{ display: "flex" }} mb={1}>
                    <Button
                      size="small"
                      variant="outlined"
                      sx={{
                        mt: 0.5,
                        height: { xs: "25px", md: "30px" },
                        textTransform: "none",
                        color: "black",
                        fontSize: { xs: "9px", md: "14px" },
                      }}
                    >
                      Trained
                    </Button>
                    <Box
                      sx={{
                        background: "rgba(255, 255, 255, 0.05)",
                        borderRadius: "6px",
                        p: "5px",
                        pt: "10px",
                        ml: 0.5,
                        width: "100%",
                        alignItems: "center",
                        wordBreak: "break-all",
                      }}
                    >
                      <Typography sx={{ fontSize: { xs: "10px", md: "14px" } }}>
                        {item}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          )}
        </TabPanel>

        <Stack my={2} alignItems="center" justifyContent="center">
          <Box sx={{ height: "30px", mb: 1, width: "100%" }}>
            {loading && (
              <>
                <Typography
                  variant="body2"
                  color="text.secondary"
                >{`${Math.round(progress)}%`}</Typography>
                <Box
                  sx={{
                    height: "6px",
                    background: "rgba(242, 242, 242, 0.2)",
                  }}
                >
                  <LinearProgress
                    sx={{ height: "6px" }}
                    variant="determinate"
                    value={progress}
                  />
                </Box>
              </>
            )}
          </Box>
          <Button
            disabled={loading ? true : false}
            variant="contained"
            sx={{ textTransform: "none" }}
            onClick={handleRetrain}
          >
            Retrain Chatbot
          </Button>
        </Stack>
      </Box>
      {limitWarning.modal && (
        <LimitWarning info={limitWarning} onClose={warnToggle} />
      )}
    </Box>
  );
}

export default Sources;
