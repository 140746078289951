import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Stack, Typography, styled } from "@mui/material";
import LoopLogo from "../../assets/images/Logo.png";
import { ButtonFAQs, ButtonLogin, ButtonPricing } from "../user header/style";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearUser } from "../../pages/Admin/userSlice";

const RootStyle = styled(Box)({
  backdropFilter: "blur(1.5px)",
  borderRadius: "6px",
});
const MenuBox = styled(Box)(({ theme }) => ({
  zIndex: 1,
  // background: "white",
  borderRadius: "0 0 6px 6px",
  minHeight: "110px",
  flexDirection: "column",
  display: "none",
  gap: "20px",
  padding: "20px",
  [theme.breakpoints.down("md")]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
  },
}));
const ButtonAccount = styled(Button)({
  height: "27px",
  textTransform: "none",
  background: "linear-gradient(135deg, #17CDAD 0%, #186FD0 100%) !important",
  color: "#FFF",
});

function AdminHeader({ onClick }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [navbar, setNavbar] = useState(false);

  const toggle = () => {
    setNavbar((prev) => !prev);
  };

  const handleLogout = () => {
    localStorage.clear();
    dispatch(clearUser());
    navigate("/");
  };

  return (
    <RootStyle sx={{ background: "rgba(255, 255, 255, 0.1)" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          height: "53px",
          pl: 1,
        }}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <Link to="/">
            <img height="32px" width="29px" src={LoopLogo} alt="logo" />
          </Link>
          <Typography sx={{ color: "white" }}>Talk It Out</Typography>
        </Stack>

        <Button
          onClick={toggle}
          sx={{ color: "black", display: { xs: "block", md: "none" } }}
        >
          {navbar ? (
            <CloseIcon sx={{ color: "white" }} />
          ) : (
            <MenuIcon sx={{ color: "white" }} />
          )}
        </Button>

        <Box
          sx={{
            width: "60%",
            display: { xs: "none", md: "flex" },
            justifyContent: "flex-end",
            alignItems: "center",
            pr: 2,
          }}
        >
          <Link to="/demo">
            <ButtonPricing variant="contained" sx={{ mx: 1 }}>
              Demo
            </ButtonPricing>
          </Link>
          <Link to="/profile">
            <ButtonAccount variant="contained" sx={{ mx: 1 }}>
              Account
            </ButtonAccount>
          </Link>
          <Link to="/subscription">
            <ButtonPricing variant="contained" sx={{ mx: 1 }}>
              Subscription
            </ButtonPricing>
          </Link>
          <Link to="/chatbots">
            <ButtonFAQs variant="contained" sx={{ mx: 1 }}>
              My ChatBots
            </ButtonFAQs>
          </Link>
          <Box
            sx={{
              width: "1px",
              bgcolor: "white",
              height: "30px",
              mr: 1,
            }}
          />

          <ButtonLogin variant="contained" onClick={handleLogout}>
            Logout
          </ButtonLogin>
        </Box>
      </Box>
      {navbar && (
        <MenuBox>
          <Link to="/demo">
            <ButtonPricing variant="contained">Demo</ButtonPricing>
          </Link>
          <Link to="/profile">
            <ButtonAccount variant="contained">Account</ButtonAccount>
          </Link>
          <Link to="/subscription">
            <ButtonPricing variant="contained">Subscription</ButtonPricing>
          </Link>
          <Link to="/chatbots">
            <ButtonFAQs variant="contained ">My ChatBots</ButtonFAQs>
          </Link>
          <Link>
            <ButtonLogin variant="contained" onClick={handleLogout}>
              Logout
            </ButtonLogin>
          </Link>
        </MenuBox>
      )}
    </RootStyle>
  );
}

export default AdminHeader;
