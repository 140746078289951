import Repository from "./Repository";

export default {
  default(payload) {
    return Repository.post("", payload);
  },
  email(payload) {
    return Repository.post("email", payload);
  },
  user(payload) {
    return Repository.post("users", payload);
  },
  chatbot(payload) {
    return Repository.post("chatbots", payload);
  },
  history(payload) {
    return Repository.post("history", payload);
  },
  subscribe(payload) {
    return Repository.post("subscription", payload);
  },
  feedback(payload) {
    return Repository.post("feedback", payload);
  },
  script(payload) {
    return Repository.post("scripts", payload);
  },
};
