import React, { useEffect, useState } from "react";
import { Stack, Dialog, DialogContent, Box, TextField } from "@mui/material";
import { InputLabel, Button, Typography } from "@mui/material";
import styled from "styled-components";
import { loadStripe } from "@stripe/stripe-js";
import TailSpinLoader from "../../components/TailSpinLoader";
import Toast from "../../components/Toast";
import Repo from "../../repo/Repo";
import moment from "moment";
import { useDispatch } from "react-redux";
import { getUser } from "../Admin/userSlice";

const stripePromise = loadStripe(
  "pk_live_51MvEfmACTSkJyVrNZS7mwZeAWTfBdt9bdOt4hjEGITgha0tYAqiDyIQAoLXbnAls3BW8InDflsFPfB26AwIMOBV900oQBcdRgj"
);

export const Btn = styled(Button)({
  backgroundColor: "rgba(96, 207, 101, 1) !important",
  boxShadow: "0px 4px 4px rgba(41, 89, 43, 0.2)",
  borderRadius: "30px",
});
const Payment = ({ open, plan, onClose }) => {
  let email = localStorage.getItem("email") ?? "";
  const dispatch = useDispatch();
  const [state, setState] = useState({
    loading: false,
    email: email,
    package: "",
    cardNumber: "",
    expireDate: "",
    expireYear: "",
    cvc: "",
    error: "",
    errorText: "",
  });
  const [payModal, setPayModal] = useState(false);
  const [success, setSuccess] = useState(false);

  const toggle = () => {
    if (!state.loading) {
      onClose();
    }
  };
  const handleInput = (e) => {
    const { name, value } = e.target;
    if (name == "cvc") {
      if (value?.length <= 3) {
        setState((prev) => ({ ...prev, [name]: value }));
      }
    } else if (name == "expireDate") {
      if (value?.length <= 5) {
        if (state.expireDate.length == 0 && value > 1) {
          let val = 0 + value + "/";
          setState((prev) => ({ ...prev, [name]: val }));
        } else if (state?.expireDate?.length == 4 && value.length == 3) {
          let val = value.replace("/", "");
          setState((prev) => ({ ...prev, [name]: val }));
        } else if (state?.expireDate?.length == 1 && value.length == 2) {
          let val = value + "/";
          setState((prev) => ({ ...prev, [name]: val }));
        } else if (state.expireDate.length == 2 && value.length == 3) {
          let val = value.split("");
          val.splice(2, 0, "/");
          let final = val.join("");
          setState((prev) => ({ ...prev, [name]: final }));
        } else {
          setState((prev) => ({ ...prev, [name]: value }));
        }
      }
    } else {
      setState((prev) => ({ ...prev, [name]: value }));
    }
    if (state.error) {
      if (state.error == name && value) {
        setState((prev) => ({ ...prev, error: "", errorText: "" }));
      }
    }
  };
  const payToggle = () => {
    setPayModal((prev) => !prev);
  };

  const handlePay = async () => {
    setState((prev) => ({ ...prev, loading: true }));
    if (state.res.clientSecret) {
      let { paymentMethodId, clientSecret } = state.res;
      const stripe = await stripePromise;
      stripe
        .confirmCardPayment(clientSecret, {
          payment_method: paymentMethodId,
        })
        .then(async (result) => {
          console.log("result:", result);
          if (result?.error) {
            Toast("error", "Error: Payment not successful");
            setState((prev) => ({ ...prev, loading: false }));
            setPayModal(false);
          } else {
            let { data } = await Repo.subscribe({
              request: {
                method: "subscribePlan",
                data: {
                  email: state.email,
                  package: plan,
                },
              },
            });
            setState((prev) => ({ ...prev, loading: false }));
            if (data.response.data.subscribed == "Successful") {
              Toast("success", "Payment Success");
              setSuccess(true);
              setPayModal(false);
            } else {
              Toast("error", "Package not subscribed");
              setPayModal(false);
            }
          }
        });
    } else {
      Toast("info", "Something went wrong");
      payToggle();
    }
  };

  const handleClose = () => {
    dispatch(getUser());
    setSuccess(false);
    setPayModal(false);
    onClose();
  };

  const handleSubmit = async () => {
    try {
      let currentYear = moment().format("YYYY");
      if (!state.email) {
        setState((prev) => ({
          ...prev,
          error: "email",
          errorText: "Email is required",
        }));
        return;
      }
      if (!state.cardNumber) {
        setState((prev) => ({
          ...prev,
          error: "cardNumber",
          errorText: "Email is required",
        }));
        return;
      }
      if (!state.expireDate || state.expireDate.length < 5) {
        setState((prev) => ({
          ...prev,
          error: "expireDate",
          errorText: "Enter proper expire date",
        }));
        return;
      }
      let year = state.expireDate.slice(3, 5);
      let month = state.expireDate.slice(0, 2);
      let selectedYear = 20 + year;

      if (currentYear > selectedYear) {
        setState((prev) => ({
          ...prev,
          error: "expireDate",
          errorText: "Expire Year is in past",
        }));
        return;
      }
      if (currentYear == selectedYear) {
        let currentMonth = moment().format("MM");
        if (currentMonth > month) {
          setState((prev) => ({
            ...prev,
            error: "expireDate",
            errorText: "Expire Year is in past",
          }));
          return;
        }
      }

      if (!state.cvc) {
        setState((prev) => ({
          ...prev,
          error: "cvc",
          errorText: "CVC is required",
        }));
        return;
      }

      setState((prev) => ({ ...prev, loading: true }));
      let { data } = await Repo.subscribe({
        request: {
          method: "processSubscription",
          data: {
            email: state.email,
            package: plan,
            cardDetails: {
              cardNumber: state.cardNumber,
              expMonth: month,
              expYear: 20 + year,
              cvc: state.cvc,
            },
          },
        },
      });
      console.log("data:", data);
      // setState((prev) => ({ ...prev, loading: false }));

      if (data.response?.data?.subscribed == "Successful") {
        setState((prev) => ({
          ...prev,
          loading: false,
          res: data.response?.data,
        }));
        setPayModal(true);
      } else {
        console.log(data.response.status.statusMessage);
        Toast("error", data.response.status.statusMessage);
        setState((prev) => ({ ...prev, loading: false }));
      }
    } catch (err) {
      console.log("err:", err);
      Toast("error", "Error");
      setState((prev) => ({ ...prev, loading: false }));
    }
  };
  return (
    <Dialog open={open} onClose={toggle} fullWidth maxWidth="sm">
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant="h6" sx={{ textAlign: "center" }}>
            Card Details
          </Typography>
          <Stack>
            <InputLabel sx={{ mb: 0.5 }}>Package</InputLabel>
            <TextField
              fullWidth
              disabled
              size="small"
              name="package"
              variant="outlined"
              value={plan}
            />
          </Stack>
          <TextField
            fullWidth
            error={state.error == "email" ? true : false}
            size="small"
            label="Email"
            name="email"
            variant="outlined"
            value={state.email}
            onChange={handleInput}
            helperText={state.error == "email" ? state.errorText : ""}
          />
          <TextField
            fullWidth
            error={state.error == "cardNumber" ? true : false}
            size="small"
            type="number"
            label="Card Number"
            name="cardNumber"
            variant="outlined"
            value={state.cardNumber}
            onChange={handleInput}
            helperText={state.error == "cardNumber" ? state.errorText : ""}
          />

          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <Stack>
              <InputLabel sx={{ mb: 0.5 }}>Expiration</InputLabel>
              <TextField
                fullWidth
                error={state.error == "expireDate" ? true : false}
                size="small"
                name="expireDate"
                placeholder="MM / YY"
                variant="outlined"
                value={state.expireDate}
                onChange={handleInput}
                helperText={state.error == "expireDate" ? state.errorText : ""}
              />
            </Stack>
            <Stack>
              <InputLabel sx={{ mb: 0.5 }}>CVC</InputLabel>
              <TextField
                fullWidth
                error={state.error == "cvc" ? true : false}
                type="number"
                size="small"
                name="cvc"
                placeholder="567"
                variant="outlined"
                value={state.cvc}
                onChange={handleInput}
                helperText={state.error == "cvc" ? state.errorText : ""}
              />
            </Stack>
          </Stack>
          {state.loading && (
            <Stack direction="row" justifyContent="space-evenly">
              <TailSpinLoader />
            </Stack>
          )}
          {!state.loading && (
            <Stack direction="row" justifyContent="space-evenly">
              <Button
                variant="outlined"
                sx={{ textTransform: "none" }}
                onClick={toggle}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  background: "rgba(31, 176, 133, 1) !important",
                }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Stack>
          )}
        </Stack>
      </DialogContent>
      <Dialog open={payModal} onClose={() => {}} fullWidth maxWidth="xs">
        <DialogContent>
          <Stack py={5} direction="row" justifyContent="space-evenly">
            {state.loading && <TailSpinLoader />}
            {!state.loading && (
              <Stack alignItems="center" justifyContent="center">
                {success ? (
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    spacing={2}
                  >
                    <Typography
                      variant="h6"
                      sx={{ color: "rgba(31, 176, 133, 1)" }}
                    >
                      Congratulations!
                    </Typography>
                    <Typography>Your payment was successful</Typography>
                    <Typography>You have subscribed {plan} Package</Typography>
                    <Button variant="outlined" onClose={handleClose}>
                      Close
                    </Button>
                  </Stack>
                ) : (
                  <Button
                    variant="contained"
                    sx={{
                      background: "rgba(31, 176, 133, 1) !important",
                    }}
                    onClick={handlePay}
                  >
                    Pay Amount
                  </Button>
                )}
              </Stack>
            )}
          </Stack>
        </DialogContent>
      </Dialog>
    </Dialog>
  );
};
export default Payment;
