import Repo from "../../../repo/Repo";

export const singUp = async (email, password) => {
  let { data } = await Repo.user({
    request: {
      method: "signupUser",
      data: {
        email: email,
        password: password,
      },
    },
  });
  return data;
};

export const email = async (email) => {
  let { data } = await Repo.email({
    request: {
      method: "sendVerificationLink",
      data: {
        to: email,
      },
    },
  });
  return data;
};
