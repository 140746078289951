import React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import Pattern from "../../assets/images/footer.svg";
import LoopLogo from "../../assets/images/Logo.png";

function Footer() {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      className="backdrop-blur-2xl "
      sx={{
        background: "linear-gradient(90deg, #186ED0 0%, #17CEAD 100%)",
        padding: "2%",
      }}
    >
      <Stack
        sx={{
          position: { md: "relative" },
          display: { md: "block", xs: "none" },
        }}
      >
        <img
          src={Pattern}
          alt=""
          style={{
            mixBlendMode: "soft-light",
          }}
        />
      </Stack>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          position: { md: "absolute" },
        }}
      >
        <Grid item md={9} xs={12}>
          <Box
            sx={{
              background: "rgba(255, 255, 255, 0.1)",
              borderRadius: "12px",
              padding: "1%",
              backdropFilter: "blur(12px)",
            }}
          >
            <Stack>
              <Stack
                direction="row"
                justifyContent="center"
                spacing={2}
                alignItems="center"
              >
                <img
                  src={LoopLogo}
                  alt="logo"
                  style={{
                    width: "40px",
                    height: "40px",
                  }}
                />

                <Typography
                  variant="h6"
                  sx={{ color: "lightcyan", fontWeight: 400 }}
                >
                  Talk It Out
                </Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                sx={{ color: "#3D5163" }}
              >
                <Typography sx={{ fontSize: { md: "20px", xs: "16px" } }}>
                  Email {"  "}
                </Typography>
                <Typography sx={{ fontSize: { md: "20px", xs: "16px" } }}>
                  {" "}
                  | Info@TalkItOut.Ai
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default Footer;
