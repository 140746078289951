import React, { useState } from "react";
import { Box, Button, Checkbox, InputAdornment } from "@mui/material";
import { LinearProgress, TextField, Stack, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Repo from "../../repo/Repo";
import Toast from "../../components/Toast";
import MyTextField from "./MyTextField";
import { useDispatch, useSelector } from "react-redux";
import { addUrls, clearUrls } from "./createbotSlice";
import { Title } from "./styles";

function Website() {
  const dispatch = useDispatch();
  const { urls, loading } = useSelector((state) => state.createbot);
  const [siteUrl, setSiteUrl] = useState("");
  const [selected, setSelected] = useState([]);
  const [linksProgress, setLinksProgress] = useState(1);
  const [linksLoading, setLinksLoading] = useState(false);
  const [manual, setManual] = useState(false);
  const [manualText, setManualText] = useState("");

  const found = urls.reduce((acc, item) => {
    acc[item] = item;
    return acc;
  }, {});

  const handleInput = (e) => {
    const { value } = e.target;
    setSiteUrl(value);
  };
  const handleChange = (e) => {
    const { value } = e.target;
    setManualText(value);
  };

  const manualToggle = () => {
    setManual((prev) => !prev);
  };

  const handleFetchLinks = async () => {
    if (!siteUrl) {
      Toast("info", "Kindly enter site url");
      return;
    }
    setSelected([]);
    dispatch(clearUrls());
    setLinksLoading(true);
    const linksTimer = setInterval(() => {
      setLinksProgress((prev) => (prev >= 100 ? 1 : prev + 1));
    }, 500);
    let { data } = await Repo.chatbot({
      request: {
        method: "getAllUrls",
        data: {
          url: siteUrl,
        },
      },
    });
    let res = data.response.data.scrappedUrls;
    clearInterval(linksTimer);
    if (res.length > 0) {
      setLinksLoading(false);
      setSelected(res);
    } else {
      setManual(true);
      Toast("info", "Links not fetched, Add Manually");
    }
    setLinksProgress(1);
  };

  const handleManually = () => {
    if (!manualText) {
      Toast("info", "Kindly add manual link");
      return;
    }
    dispatch(addUrls([manualText, ...urls]));
    setSelected((prev) => [manualText, ...prev]);
    setManualText("");
  };

  const handleSelect = (item) => {
    let found = urls.find((i) => i == item);
    if (found) {
      let filtered = urls.filter((i) => i != item);
      dispatch(addUrls(filtered));
    } else {
      if (urls.length >= 10) {
        Toast("info", "Only 10 links are allowed");
      } else {
        dispatch(addUrls([item, ...urls]));
      }
    }
  };

  const filtered = () => {
    if (selected.length == 0) {
      return urls;
    } else {
      return selected;
    }
  };

  return (
    <Stack justifyContent="center" alignItems="center" spacing={2}>
      <Title>Add or Fetch Website Links</Title>

      <Stack width="100%">
        <MyTextField
          placeholder="https://www.example.com"
          title="Fetch Links"
          onChange={handleInput}
          onClick={handleFetchLinks}
          value={siteUrl}
        />

        <Box>
          <Button
            sx={{ float: "right", textTransform: "none", color: "white" }}
            onClick={manualToggle}
          >
            Add Manually
          </Button>
          {manual && (
            <TextField
              fullWidth
              size="small"
              placeholder="https://www.example.com"
              sx={{
                background: "rgba(255, 255, 255, 0.05)",
                borderRadius: "6px",
                height: "40px",
                color: "rgba(53, 53, 53, 0.5)",
                paddingRight: "0px",
              }}
              onKeyDown={(e) => {
                if (e.key == "Enter") {
                  handleManually();
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <AddCircleOutlineIcon
                      sx={{ cursor: "pointer" }}
                      onClick={handleManually}
                    />
                  </InputAdornment>
                ),
              }}
              value={manualText}
              onChange={handleChange}
            />
          )}
        </Box>
        <Box sx={{ height: "10px", mb: 1, width: "100%" }}>
          {linksLoading && (
            <>
              <Typography variant="body2" color="text.secondary">{`${Math.round(
                linksProgress
              )}%`}</Typography>
              <Box
                sx={{
                  height: "6px",
                  background: "rgba(242, 242, 242, 0.2)",
                }}
              >
                <LinearProgress
                  sx={{ height: "6px" }}
                  variant="determinate"
                  value={linksProgress}
                />
              </Box>
            </>
          )}
        </Box>
      </Stack>

      <Typography fontSize="small">
        This will crawl all the links starting with the URL (not including files
        on the website).
      </Typography>

      <Stack width="100%" direction="row" justifyContent="space-evenly">
        {filtered().length > 0 && (
          <Typography>Your links - (select desired 10 links)</Typography>
        )}
      </Stack>

      <Stack width="100%" maxHeight="350px" overflow="auto" spacing={0.5}>
        {filtered() &&
          filtered().map((item, i) => {
            return (
              <Box key={i} sx={{ display: "flex" }}>
                <Checkbox
                  disabled={loading ? true : false}
                  checked={found[item] ? true : false}
                  onChange={() => handleSelect(item)}
                  inputProps={{ "aria-label": "controlled" }}
                  sx={{
                    "&.Mui-checked": {
                      color: "white",
                    },
                  }}
                />
                <Box
                  sx={{
                    background: "rgba(255, 255, 255, 0.05)",
                    borderRadius: "6px",
                    p: "5px",
                    pt: "10px",
                    width: "89%",
                    display: "block",
                    alignItems: "center",
                    wordWrap: "break-word",
                    cursor: "pointer",
                  }}
                  onClick={() => handleSelect(item)}
                >
                  <Typography>{item}</Typography>
                </Box>
              </Box>
            );
          })}
      </Stack>
    </Stack>
  );
}

export default Website;
