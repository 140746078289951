import { Box, Typography, styled } from "@mui/material";

export const RootStyle = styled(Box)(({ theme }) => ({
  background: "#FFFFFF",
  boxShadow: "0px 0px 60px rgba(0, 0, 0, 0.2)",
  borderRadius: "12px",
  width: "100%",
  padding: "8px",
  minHeight: "570px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  [theme.breakpoints.down("md")]: {
    minHeight: "448px",
  },
}));

export const IconBox = styled(Box)({
  background: "#EEEEEE",
  height: "35px",
  width: "35px",
  borderRadius: "50px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const SubTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "Raleway",
  fontWeight: 500,
  fontSize: "13px",
  color: "#828282",
  wordBreak: "break-word",
}));
