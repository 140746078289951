import React, { useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import starndardIcon from "../../../assets/images/standard icon.svg";
import DoneIcon from "@mui/icons-material/Done";
import { IconBox, RootStyle, SubTitle } from "./styles";
import { BtnSubscribed } from "../style";
import { useSelector } from "react-redux";
import UpdatePackage from "./UpdatePackage";

function Standard({ onClick }) {
  const { isLoggedIn, user } = useSelector((state) => state.user);
  let type = user?.subscription?.subType;
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen((prev) => !prev);
  };

  const handleChoose = () => {
    if (isLoggedIn) {
      type != "free" ? toggle() : onClick();
    } else {
      onClick();
    }
  };

  return (
    <RootStyle>
      <Stack>
        <Stack
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
        >
          <IconBox>
            <img width="22px" height="22px" src={starndardIcon} alt="" />
          </IconBox>
          <Stack>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: 500,
                fontSize: "12px",
                color: "#C8C8C8",
                textAlign: "center",
              }}
            >
              PLAN TYPE
            </Typography>
            <Typography
              sx={{
                fontFamily: "Raleway",
                fontWeight: 700,
                fontSize: { xs: "16px", md: "20px" },
                color: "#353535",
                textAlign: "center",
              }}
            >
              Standard
            </Typography>
          </Stack>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontWeight: 600,
              fontSize: { xs: "16px", md: "20px" },
              color: "#ECB119",
            }}
          >
            $49
          </Typography>
        </Stack>

        <Stack alignItems="center" justifyContent="center">
          <Typography
            sx={{
              fontFamily: "Urbanist",
              fontWeight: 600,
              fontSize: "13px",
              color: "#ECB119",
            }}
          >
            Billed Monthly
          </Typography>
        </Stack>

        <Stack mt={1}>
          <Stack direction="row" spacing={1}>
            <DoneIcon sx={{ color: "#ECB119" }} />
            <SubTitle sx={{ color: "#828282" }}>
              15,000
              <span style={{ marginLeft: "5px", fontWeight: 500 }}>
                message credits /month ( Messages over the limit will use your
                OpenAI API Key ).
              </span>
            </SubTitle>
          </Stack>
          <Stack direction="row" spacing={1}>
            <DoneIcon sx={{ color: "#ECB119" }} />
            <SubTitle sx={{ color: "#828282" }}>
              Unlimited
              <span style={{ marginLeft: "5px", fontWeight: 500 }}>
                chatbots.
              </span>
            </SubTitle>
          </Stack>
          <Stack direction="row" spacing={1}>
            <DoneIcon sx={{ color: "#ECB119" }} />
            <SubTitle sx={{ color: "#828282" }}>
              6,000,000
              <span style={{ marginLeft: "5px", fontWeight: 500 }}>
                characters / chatbot.
              </span>
            </SubTitle>
          </Stack>
          <Stack direction="row" spacing={1}>
            <DoneIcon sx={{ color: "#ECB119" }} />
            <SubTitle sx={{ color: "#828282" }}>
              Embed
              <span style={{ marginLeft: "5px", fontWeight: 500 }}>
                on unlimited websites.
              </span>
            </SubTitle>
          </Stack>
          <Stack direction="row" spacing={1}>
            <DoneIcon sx={{ color: "#ECB119" }} />
            <SubTitle sx={{ color: "#828282" }}>
              Upload
              <span style={{ marginLeft: "5px", fontWeight: 500 }}>
                up to 15 files.
              </span>
            </SubTitle>
          </Stack>
          <Stack direction="row" spacing={1}>
            <DoneIcon sx={{ color: "#ECB119" }} />
            <SubTitle sx={{ color: "#828282" }}>
              API
              <span style={{ marginLeft: "5px", fontWeight: 500 }}>Access</span>
            </SubTitle>
          </Stack>
          <Stack direction="row" spacing={1}>
            <DoneIcon sx={{ color: "#ECB119" }} />
            <SubTitle sx={{ color: "#828282" }}>
              Option to choose
              <span style={{ margin: "0 5px", fontWeight: 700 }}>GPT-4</span>
              or
              <span style={{ margin: "0 5px", fontWeight: 700 }}>Claude</span>
              or
              <span style={{ margin: "0 5px", fontWeight: 700 }}>Bard</span>
              model ( upcoming ).
            </SubTitle>
          </Stack>
          <Stack mt={1} direction="row" spacing={1}>
            <DoneIcon sx={{ color: "#ECB119" }} />
            <SubTitle sx={{ color: "#828282" }}>
              Share
              <span style={{ marginLeft: "5px", fontWeight: 500 }}>
                chatbot with everyone through a simple link.
              </span>
            </SubTitle>
          </Stack>
        </Stack>
      </Stack>
      <Stack mt={1} justifyContent="center" alignItems="center">
        {user?.subscription?.subType === "standard" ? (
          <BtnSubscribed fullWidth>Subscribed</BtnSubscribed>
        ) : (
          <Button
            fullWidth
            size="small"
            variant="contained"
            sx={{
              background: "#FFF8E8 !important",
              border: "1px solid #E4B747",
              borderRadius: "6px",
              textTransform: "none",
              color: "#E4B747",
            }}
            onClick={handleChoose}
          >
            Choose Plan
          </Button>
        )}
      </Stack>
      {open && <UpdatePackage open={open} onClose={toggle} title="standard" />}
    </RootStyle>
  );
}

export default Standard;
