import React, { useState } from "react";
import ForgotPassword from "../Admin/ForgotPassword";
import LandingHeader from "../../layout/header";
import { Link, useLocation } from "react-router-dom";
import LoopLogo from "../../assets/images/Logo.png";
import Signup from "../authentication/sign up";
import Login from "../authentication/login";
import { Box, Button, Grid, Stack, styled, Typography } from "@mui/material";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { useSelector } from "react-redux";
import AdminHeader from "../../layout/user header";

export const RootStyle = styled(Box)({
  background: "linear-gradient(90deg, #186ED0 0%, #17CEAD 100%)",
  padding: "2% 5%",
});

const HomeSection = ({ onSuccess }) => {
  const location = useLocation();
  const { isLoggedIn } = useSelector((state) => state.user);
  const [state, setState] = useState({
    loginModal: location.state ? true : false,
    forgotModal: false,
    signupModal: false,
  });

  const toggle = (name) => {
    if (name === "faqs" || name === "pricing") {
      onSuccess(name);
    } else if (name === "forgotModal" || name === "signupModal") {
      setState((prev) => ({ ...prev, [name]: !prev[name], loginModal: false }));
    } else {
      if (state.email || state.password) {
        setState((prev) => ({
          ...prev,
          [name]: !prev[name],
          email: "",
          password: "",
        }));
      } else {
        setState((prev) => ({ ...prev, [name]: !prev[name] }));
      }
    }
  };

  return (
    <RootStyle>
      {isLoggedIn ? (
        <AdminHeader />
      ) : (
        <LandingHeader onClick={(e) => toggle(e)} />
      )}

      <Stack height="100vh" alignItems="center">
        <img src={LoopLogo} alt="logo" width="250px" height="270" />
        <Stack alignItems="center" height="100%" spacing={3}>
          <Typography
            sx={{
              color: "white",
              fontWeight: 900,
              fontSize: { md: "40px", xs: "18px" },
            }}
          >
            Your Custom {"  "}
            <span style={{ color: "#353535" }}>AI </span> ChatBot
          </Typography>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item md={6}>
              <p
                style={{
                  color: "#F2F2F2",
                  fontWeight: 300,
                  lineHeight: "30px",
                  fontSize: "20px",
                  font: "Raleway",
                }}
              >
                Simply upload a document or add links to your website pages and
                get a custom AI chatbot that can answer any question on it, then
                add it as a widget to your website.
              </p>
            </Grid>
          </Grid>
          <Stack spacing={1}>
            {isLoggedIn ? (
              <Link to="/create-chatbot">
                <Button
                  variant="contained"
                  sx={{ textTransform: "capitalize" }}
                >
                  <SmartToyIcon sx={{ mx: 1 }} />
                  Create Chatbot
                  <KeyboardDoubleArrowRightIcon />
                </Button>
              </Link>
            ) : (
              <Button
                variant="contained"
                sx={{ textTransform: "capitalize" }}
                onClick={() => toggle("loginModal")}
              >
                <SmartToyIcon sx={{ mx: 1 }} />
                Build your chatbot
                <KeyboardDoubleArrowRightIcon />
              </Button>
            )}
            <a
              href="https://www.producthunt.com/posts/talkitout?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-talkitout"
              target="_blank"
            >
              <img
                src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=404095&theme=light"
                alt="Talkitout - Your&#0032;Custom&#0032;AI&#0032;ChatBot | Product Hunt"
                style={{ width: "250px", height: "54px" }}
                width="250"
                height="54"
              />
            </a>
          </Stack>
        </Stack>
      </Stack>

      {state.loginModal && (
        <Login open={state.loginModal} onClose={(e) => toggle(e)} />
      )}
      {state.forgotModal && (
        <ForgotPassword
          open={state.forgotModal}
          onClose={() => toggle("forgotModal")}
        />
      )}
      {state.signupModal && (
        <Signup
          open={state.signupModal}
          onClose={() => toggle("signupModal")}
        />
      )}
    </RootStyle>
  );
};

export default HomeSection;
