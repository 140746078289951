import moment from "moment";

export const FormattedDate = (arg) => {
  const date = arg ? new Date(moment.unix(arg)) : new Date();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const formattedDate = `${monthNames[month]} ${day}, ${year}`;
  return formattedDate;
};
