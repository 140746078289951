import React, { useRef, useState } from "react";
import { Box, Button, FormControl, Grid } from "@mui/material";
import { InputAdornment, Stack, TextField, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { pdfjs } from "react-pdf";
import { useDispatch, useSelector } from "react-redux";
import { addFile, fileCounts, removeFile } from "./createbotSlice";
import { Title } from "./styles";
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function File() {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const { loading, allFiles, counts } = useSelector((state) => state.createbot);
  const [state, setState] = useState({
    file: "",
  });
  console.log("state:", state);
  const [numCharacters, setNumCharacters] = useState(0);

  const handleOpen = () => {
    inputRef.current.click();
  };

  const handleDelete = (index) => {
    if (loading) {
      return;
    }
    dispatch(removeFile(index));
    let countCopy = [...counts];
    countCopy.splice(index, 1);
    dispatch(fileCounts(countCopy));
  };

  const readFile = (file) => {
    const reader = new FileReader();

    reader.onload = async (e) => {
      const buffer = e.target.result;

      const data = new Uint8Array(buffer);
      const pdfData = pdfjs.getDocument({ data });

      pdfData.promise.then((pdf) => {
        let characterCount = 0;

        for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
          pdf.getPage(pageNumber).then((page) => {
            page.getTextContent().then((textContent) => {
              const pageText = textContent.items
                .map((item) => item.str)
                .join("");
              characterCount += pageText.length;
              setNumCharacters(characterCount);
            });
          });
        }
      });
    };

    reader.readAsArrayBuffer(file);
  };

  const handleSelected = async () => {
    if (loading) {
      return;
    }
    dispatch(fileCounts([numCharacters, ...counts]));
    dispatch(addFile(state.file));
    setState((prev) => ({ ...prev, file: "" }));
    setNumCharacters(0);
  };
  const handleInput = (e) => {
    if (loading) {
      return;
    }
    const { files } = e.target;

    if (files) {
      readFile(files[0]);
      setState((prev) => ({ ...prev, file: files }));
    } else {
      setState((prev) => ({ ...prev, file: "" }));
    }
  };

  return (
    <Stack alignItems="center" spacing={4}>
      <Title>Upload Files</Title>
      <Typography>Attach a file to see character count in it.</Typography>
      <Grid container>
        <Grid item xs={10.5}>
          {state.file && (
            <Typography sx={{ textAlign: "center" }}>
              Please click on "+" icon to add your file once you have uploaded
              it
            </Typography>
          )}
          <Stack width="100%">
            <FormControl fullWidth>
              <TextField
                inputRef={inputRef}
                fullWidth
                variant="outlined"
                size="small"
                type="file"
                sx={{
                  height: "40px",
                  borderRadius: "4px",
                  background: "rgba(255, 255, 255, 0.05)",
                  border: "none",
                  "& fieldset": { padding: "0px", border: "none" },
                }}
                autoComplete="off"
                InputProps={{
                  style: {
                    padding: "0",
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <Button
                        variant="contained"
                        sx={{
                          textTransform: "none",
                          background: "rgba(53, 53, 53, 1) !important",
                        }}
                        onClick={handleOpen}
                      >
                        Choose File
                      </Button>
                    </InputAdornment>
                  ),
                }}
                onChange={handleInput}
              />
            </FormControl>
          </Stack>
        </Grid>

        {state.file && (
          <Grid item xs={1.2}>
            <Box
              sx={{
                ml: 2,
                width: "100%",
                height: "100%",
                background: "rgba(255, 255, 255, 0.1)",
                borderRadius: "6px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={handleSelected}
            >
              <AddCircleOutlineIcon />
            </Box>
          </Grid>
        )}
      </Grid>
      {allFiles &&
        allFiles?.map((item, i) => {
          return (
            <Stack
              key={i}
              width="100%"
              direction="row"
              justifyContent="space-between"
            >
              <Box
                sx={{
                  width: "88%",
                  background: "rgba(255, 255, 255, 0.05)",
                  borderRadius: "6px",
                  display: "flex",
                  padding: "10px",
                }}
              >
                <Typography>{item[0]?.name}</Typography>
                <Typography sx={{ ml: 5 }}>{counts[i]} characters</Typography>
              </Box>
              <Box
                sx={{
                  width: "9.5%",
                  background: "rgba(255, 255, 255, 0.1)",
                  borderRadius: "6px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <DeleteIcon
                  sx={{
                    color: "rgba(203, 76, 76, 1)",
                    marginX: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleDelete(i)}
                />
              </Box>
            </Stack>
          );
        })}

      <Typography>
        NOTE : For now only ( pdf ) format files are accepted.
      </Typography>
    </Stack>
  );
}

export default File;
