import React, { useEffect, useRef, useState } from "react";
import { DialogContent } from "@mui/material";
import { FormControl, FormHelperText, LinearProgress } from "@mui/material";
import { Button, Divider, Container, Box, Stack } from "@mui/material";
import { Dialog, TextField, styled, Typography } from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";
import { Scrollbar } from "react-scrollbars-custom";
import SendIcon from "@mui/icons-material/Send";
import Repo from "../../../repo/Repo";
import { Link } from "react-router-dom";
import Toast from "../../../components/Toast";
import { FormattedDate } from "../../../utils/FormattedDate";
import { useSelector } from "react-redux";

const TextBox = styled(Box)({
  display: "inline-block",
  background: "rgba(255, 255, 255, 0.12)",
  padding: "16px",
  margin: "4px",
  borderRadius: "4px",
  maxWidth: "70%",
});

function Chat() {
  const { chatbot } = useSelector((state) => state.chatbot);
  const { user } = useSelector((state) => state.user);
  const leftMsg = parseInt(user?.subscription?.messages) || 0;
  const [history, setHistory] = useState([]);
  const [query, setQuery] = useState("");
  const [hId, setHId] = useState(0);
  const [conversation, setConversation] = useState([]);
  const [question, setQuestion] = useState("");
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(1);
  const divRef = useRef(null);
  const [change, setChange] = useState(0);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (change && filtered()?.length > 2) {
      divRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [change]);
  useEffect(() => {
    getHistory();
  }, []);

  const toggle = () => {
    setOpen((prev) => !prev);
  };

  const getHistory = async () => {
    let { data } = await Repo.history({
      request: {
        method: "getHistoryByBotId",
        data: {
          botId: chatbot._id,
        },
      },
    });

    if (data?.response?.data?.historyArr) {
      setHistory(data?.response?.data?.historyArr);
    }
    if (data?.response?.data?.historyArr?.length == 15) {
      setOpen(true);
    }
  };
  const handleSubmit = async () => {
    setLoading(true);
    const timer = setInterval(() => {
      setProgress((prev) => (prev >= 100 ? 1 : prev + 1));
    }, 800);
    setQuestion(query);
    setChange((prev) => prev + 1);
    let newChat = [];
    newChat.push(query);
    let myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer V78L3fNtjQ7quJ94h8YVSg==1VYOuAp34mHUAbPp"
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `https://api.urhja.com/query_chatbot?query=${query}?&botId=${chatbot._id}&hId=`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        clearInterval(timer);
        let res = JSON.parse(result);
        if (res.response) {
          newChat.push(res.response);
          setConversation((prev) => [...prev, { history: newChat }]);
          setQuestion("");
          setQuery("");
          setLoading(false);
          setProgress(1);
          setChange((prev) => prev + 1);
          if (filtered()?.length == 14) {
            setOpen(true);
          }
        } else {
          setQuestion("");
          newChat.push(res.error);
          setConversation((prev) => [...prev, { history: newChat }]);
          setLoading(false);
          setProgress(1);
          clearInterval(timer);
          Toast("error", res.error);
        }
      })
      .catch((error) => {
        console.log("error:", error);
        setLoading(false);
        setProgress(1);
        clearInterval(timer);
        Toast("error", "Error");
      });
  };

  const handleInput = (e) => {
    const { value } = e.target;
    setQuery(value);
  };
  const filtered = () => {
    return [...history, ...conversation];
  };
  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          background: "rgba(255, 255, 255, 0.1)",
          borderRadius: "6px",
          padding: "8px",
          height: "480px",
          width: { xs: "100%", md: "50%" },
        }}
      >
        <Stack
          height="36px"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            sx={{
              fontFamily: "Raleway",
              fontWeight: 500,
              fontSize: "12px",
              color: "rgba(53, 53, 53, 0.5)",
            }}
          >
            Today - {FormattedDate()}
          </Typography>
          <CachedIcon
            sx={{
              color: "rgba(53, 53, 53, 0.5)",
            }}
          />
        </Stack>
        <Divider />
        <Scrollbar
          id="chat-window"
          style={{ height: 340, overflowX: "hidden" }}
        >
          <TextBox>
            <Typography
              sx={{
                fontFamily: "Raleway",
                fontWeight: 500,
                fontSize: "12px",
              }}
            >
              How can i help you?
            </Typography>
          </TextBox>

          {filtered()?.map((item, i) => (
            <div key={i} ref={divRef}>
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <TextBox
                  sx={{
                    background: "#2677D9",
                    justifyContent: "end",
                    mt: "8px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Raleway",
                      fontWeight: 500,
                      fontSize: "12px",
                      color: "rgba(255, 255, 255, 1)",
                    }}
                  >
                    {item?.history[0]}
                  </Typography>
                </TextBox>
              </Box>
              <TextBox>
                <Typography
                  sx={{
                    fontFamily: "Raleway",
                    fontWeight: 500,
                    fontSize: "12px",
                  }}
                >
                  {item?.history[1]}
                </Typography>
              </TextBox>
            </div>
          ))}

          {question && (
            <Box sx={{ display: "flex", justifyContent: "end" }}>
              <TextBox
                sx={{
                  background: "#2677D9",
                  display: "inline-block",
                  justifyContent: "end",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Raleway",
                    fontWeight: 500,
                    fontSize: "12px",
                    color: "rgba(255, 255, 255, 1)",
                  }}
                >
                  {question}
                </Typography>
              </TextBox>
            </Box>
          )}
        </Scrollbar>
        <FormControl fullWidth>
          <FormHelperText
            sx={{
              p: "8px",
              fontFamily: "Raleway",
              fontWeight: 500,
              fontSize: "12px",
              color:
                leftMsg <= filtered()?.length ? "red" : "rgba(53, 53, 53, 0.5)",
            }}
          >
            {leftMsg <= filtered()?.length
              ? "Limit Exceeded!"
              : `${leftMsg - filtered()?.length}  message credits left`}
          </FormHelperText>

          {loading ? (
            <>
              <Typography variant="body2" color="text.secondary">{`${Math.round(
                progress
              )}%`}</Typography>
              <Box
                sx={{
                  height: "6px",
                  background: "rgba(242, 242, 242, 0.2)",
                }}
              >
                <LinearProgress
                  sx={{ height: "6px" }}
                  variant="determinate"
                  value={progress}
                />
              </Box>
            </>
          ) : (
            <Stack>
              {leftMsg <= filtered()?.length ? (
                <Stack alignItems="center" justifyContent="center">
                  <Link to="/subscription">
                    <Button
                      variant="contained"
                      sx={{
                        textTransform: "none",
                        background: "rgba(33, 181, 190, 1) !important",
                      }}
                    >
                      Subscribe
                    </Button>
                  </Link>
                </Stack>
              ) : (
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="Ask..."
                  InputProps={{
                    endAdornment: (
                      <SendIcon
                        onClick={handleSubmit}
                        sx={{
                          color: "rgba(53, 53, 53, 0.5)",
                          cursor: "pointer",
                        }}
                      />
                    ),
                  }}
                  sx={{
                    border: "1px solid rgba(53, 53, 53, 0.5)",
                    borderRadius: "4px",
                  }}
                  name="query"
                  value={query}
                  onChange={handleInput}
                  onKeyDown={(e) => {
                    if (e.key == "Enter") {
                      handleSubmit();
                    }
                  }}
                />
              )}
            </Stack>
          )}
        </FormControl>
      </Box>
      <Dialog open={open} onClose={toggle}>
        <DialogContent>
          <Stack alignItems="center" justifyContent="center" spacing={3}>
            <Typography variant="h6">Messages Limit Exceeded</Typography>
            <Typography>please upgrade your subscription here</Typography>
            <Link to="/subscription">
              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  background: "rgba(33, 181, 190, 1) !important",
                }}
              >
                Subscribe
              </Button>
            </Link>
          </Stack>
        </DialogContent>
      </Dialog>
    </Container>
  );
}

export default Chat;
