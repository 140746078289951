import React, { useEffect, useState } from "react";
import Chat from "./Chat";
import Sources from "./Sources";
import { Box, Typography, styled, Tabs, Button } from "@mui/material";
import { Tab, Grid, Dialog, DialogContent, Stack } from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoneIcon from "@mui/icons-material/Done";
import Embeds from "./Embeded";
import Deleted from "./Delete";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../../home/Footer";
import ReactGA from "../../../Analytics";
import AdminHeader from "../../../layout/user header";
import { useSelector } from "react-redux";
import Feedback from "../../home/Feedback";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ mt: 2 }}>{children}</Box>}
    </div>
  );
}
const StyledTab = styled(Tab)({
  backgroundColor: "rgba(255, 255, 255, 0.05)",
  fontFamily: "Raleway",
  textTransform: "none",
  fontSize: "10px",
  borderRadius: "6px 0px 0px 6px",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.5)",
  },
});

const RootStyle = styled(Box)({
  background: "linear-gradient(90deg, #186ED0 0%, #17CEAD 100%)",
  minHeight: "100vh",
  padding: "2% 5%",
});

function ChatbotDetails() {
  const location = useLocation();
  let id = location?.state?.botId;
  const theme = useTheme();
  const lastVisited = localStorage.getItem("lastVisited");
  const { chatbot } = useSelector((state) => state.chatbot);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [value, setValue] = useState(0);
  const [show, setShow] = useState(false);
  const [copy, setCopy] = useState(false);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: "Chatbot Details",
    });
    if (lastVisited) {
      setValue(1);
    }
  }, []);

  const handleCopy = () => {
    navigator.clipboard
      .writeText(`https://talkitout.ai/chat/${chatbot._id}`)
      .then(() => {
        setCopy(true);
      })
      .catch((error) => {
        console.error("Failed to copy text: ", error);
      });
  };

  const handleChange = (event, newValue) => {
    if (newValue == 3) {
      toggle();
    } else {
      setValue(newValue);
    }
  };
  const toggle = () => {
    setShow((prev) => !prev);
  };

  return (
    <RootStyle>
      <AdminHeader />
      <Grid container sx={{ mt: 2 }}>
        <Grid item md={3.5} xs={12}>
          <Typography
            sx={{
              fontFamily: "Raleway",
              fontWeight: 700,
              fontSize: "24px",
              color: "#353535",
              textAlign: "center",
            }}
          >
            My ChatBots
          </Typography>
        </Grid>
        <Grid item md={5} xs={12}>
          <Tabs
            variant={isMobile ? "scrollable" : "standard"}
            scrollButtons
            allowScrollButtonsMobile
            TabIndicatorProps={{
              style: {
                backgroundColor: "rgba(53, 53, 53, 1)",
              },
            }}
            textColor="rgba(53, 53, 53, 1)"
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.02)",
              borderRadius: "6px",
            }}
            value={value}
            onChange={handleChange}
            centered
          >
            <StyledTab label="Chatbot" />
            {/* will be used in future */}
            {/* <StyledTab label="Dashboard" /> */}
            <StyledTab label="Manage Sources" />
            <StyledTab label="Embed on site" />
            <StyledTab label="Share Link" />
            <StyledTab label="Delete Chatbot" />
          </Tabs>
        </Grid>
      </Grid>
      <TabPanel value={value} index={0}>
        <Chat />
      </TabPanel>
      {/* will be used in future */}
      {/* <TabPanel value={value} index={1}>
        <Dashboard />
      </TabPanel>*/}
      <TabPanel value={value} index={1}>
        <Sources />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Embeds />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Deleted />
      </TabPanel>
      <Footer />
      <Dialog open={show} onClose={toggle} fullWidth>
        <DialogContent>
          <Stack spacing={1}>
            <Typography variant="h6">Share your chatbot</Typography>
            <Typography>Use this link to access the chatbot</Typography>
            <Stack alignItems="center">
              <Box
                sx={{
                  background: "rgba(53, 53, 53, 0.1)",
                  p: 1,
                  borderRadius: "5px",
                  my: 2,
                }}
              >
                {`https://talkitout.ai/chat/${id || chatbot?._id}`}
              </Box>
              <Button
                onClick={handleCopy}
                sx={{
                  borderRadius: "6px",
                  p: "5px",
                  width: "36px",
                  height: "36px",
                }}
              >
                {copy ? (
                  <DoneIcon sx={{ color: "grey" }} />
                ) : (
                  <ContentCopyIcon sx={{ color: "grey" }} />
                )}
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
      <Feedback />
    </RootStyle>
  );
}

export default ChatbotDetails;
