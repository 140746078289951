import React, { useEffect, useState } from "react";
import { Button, Dialog } from "@mui/material";
import { DialogContent, IconButton, InputAdornment, Box } from "@mui/material";
import { InputLabel, Stack, TextField, Typography } from "@mui/material";
import GmailIcon from "../../assets/images/gmail icon.png";
import PasswordIcon from "../../assets/images/password icon.png";
import { DoDisturb, Visibility, VisibilityOff } from "@mui/icons-material";
import { Delete } from "@mui/icons-material";
import freeIcon from "../../assets/images/free icon.svg";
import growthIcon from "../../assets/images/growth icon.svg";
import hobbyIcon from "../../assets/images/hobby icon.png";
import unlimitedIcon from "../../assets/images/unlimited icon.svg";
import { IconBox } from "../subscription/cards/styles";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useDispatch, useSelector } from "react-redux";
import Repo from "../../repo/Repo";
import Toast from "../../components/Toast";
import { Link, useLocation } from "react-router-dom";
import { clearUser, getUser } from "../Admin/userSlice";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CancelIcon from "@mui/icons-material/Cancel";
import TailSpinLoader from "../../components/TailSpinLoader";
import ReactGA from "../../Analytics";

function InfoSection() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  let type = user?.subscription?.subType;
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    email: user?.email || "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const location = useLocation();
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: "User Info",
    });
  }, []);

  const toggle = () => setShowPassword((show) => !show);
  const toggleOpen = () => setOpen((prev) => !prev);
  const toggleCancel = () => setModal((prev) => !prev);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleCancel = async () => {
    try {
      setLoading(true);

      let { data } = await Repo.subscribe({
        request: {
          method: "cancelSubscription",
          data: {
            email: localStorage.getItem("email"),
          },
        },
      });
      if (data.response.status.statusCode == 200) {
        Toast("success", "Subscription Cancelled");
        dispatch(getUser());
        setLoading(false);
        toggleCancel();
      } else {
        Toast("error", "Error");
        setLoading(false);
        toggleCancel();
      }
    } catch (err) {
      console.log("err:", err);
      setLoading(false);
      toggleCancel();
    }
  };
  const handleDelete = async () => {
    try {
      setLoading(true);
      let { data } = await Repo.user({
        request: {
          method: "deleteUser",
          data: {
            _id: localStorage.getItem("email"),
          },
        },
      });
      if (data.response.status.statusCode == 200) {
        Toast("success", "Accound Deleted");
        dispatch(clearUser());
        setLoading(false);
        toggleOpen();
      } else {
        Toast("error", "Error");
        setLoading(false);
        toggleOpen();
      }
    } catch (err) {
      console.log("err:", err);
      setLoading(false);
      toggleOpen();
    }
  };

  return (
    <Box sx={{ width: { xs: "95%", md: "40%" } }}>
      <Stack mt={2} spacing={3}>
        <Stack spacing={1}>
          <InputLabel
            sx={{
              fontFamily: "Raleway",
              fontWeight: 600,
              fontSize: "16px",
              color: "#353535",
            }}
          >
            Email
          </InputLabel>
          {/* <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="example@example.com"
            name="email"
            sx={{
              borderRadius: "4px",
              background: "#FFFFFF33",
              border: "none",
              "& fieldset": { padding: "0px", border: "none" },
            }}
            InputProps={{
              style: {
                padding: "0",
              },
              startAdornment: (
                <InputAdornment position="start">
                  <Box sx={{ width: "60px", background: "#FFFFFF33" }}>
                    <Stack alignItems="center" justifyContent="center">
                      <img src={GmailIcon} alt="" />
                    </Stack>
                  </Box>
                </InputAdornment>
              ),
            }}
            value={state.email}
            onChange={handleInput}
          /> */}
          <Box
            sx={{
              display: "flex",
              background: "#FFFFFF33",
              borderRadius: "4px",
              height: "40px",
            }}
          >
            <Box
              sx={{
                borderRadius: "4px 0 0 4px",
                width: "60px",
                background: "#FFFFFF33",
              }}
            >
              <Stack alignItems="center" justifyContent="center">
                <img src={GmailIcon} alt="" />
              </Stack>
            </Box>
            <Box sx={{ px: 1, display: "flex", alignItems: "center" }}>
              {state.email}
            </Box>
          </Box>
        </Stack>
        <Stack spacing={1}>
          <Stack direction="row" spacing={2}>
            <InputLabel
              sx={{
                fontFamily: "Raleway",
                fontWeight: 600,
                fontSize: "16px",
                color: "#353535",
              }}
            >
              Password
            </InputLabel>
            <InputLabel
              sx={{
                fontWeight: 400,
                fontSize: "14px",
                color: "#353535",
              }}
            >
              (can be updated)
            </InputLabel>
          </Stack>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="*********"
            name="password"
            type={showPassword ? "text" : "password"}
            sx={{
              borderRadius: "4px",
              background: "#FFFFFF33 !important",
              border: "none",
              "& fieldset": { padding: "0px", border: "none" },
            }}
            autoComplete="off"
            InputProps={{
              style: {
                padding: "0",
              },
              startAdornment: (
                <InputAdornment position="start">
                  <Box sx={{ width: "60px", background: "#FFFFFF33" }}>
                    <Stack alignItems="center" justifyContent="center">
                      <img src={PasswordIcon} alt="" />
                    </Stack>
                  </Box>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment sx={{ mr: 2 }} position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={toggle}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={state.password}
            onChange={handleInput}
          />
        </Stack>
        <Box
          sx={{
            borderRadius: "8px",
            background: "#FFFFFF33",
            minHeight: "136px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            px: "16px",
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            spacing={2}
          >
            <IconBox sx={{ width: "53px", height: "53px" }}>
              <img
                width="32px"
                height="36px"
                src={
                  type == "hobby"
                    ? hobbyIcon
                    : type == "growth"
                    ? growthIcon
                    : type == "unlimited"
                    ? unlimitedIcon
                    : freeIcon
                }
                alt=""
              />
            </IconBox>
            <Stack>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  fontSize: "14px",
                  color: "#C8C8C8",
                  textAlign: "center",
                }}
              >
                PLAN TYPE
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Raleway",
                  fontWeight: 700,
                  fontSize: { xs: "16px", md: "24px" },
                  color: "#353535",
                  textAlign: "center",
                }}
              >
                {user?.subscription?.subType}
              </Typography>
            </Stack>
          </Stack>
          <Stack alignItems="flex-end" justifyContent="flex-end">
            <Link to="/subscription">
              <Button
                variant="outlined"
                size="small"
                sx={{
                  color: "#353535CC",
                  border: "1px solid #353535CC !important",
                  textTransform: "none",
                }}
              >
                Change Plan
              </Button>
            </Link>
            <Stack direction="row" spacing={1}>
              <VerifiedIcon sx={{ color: "#35353580" }} />
              <Typography
                sx={{
                  fontFamily: "Raleway",
                  fontWeight: 700,
                  color: "#35353580",
                  fontSize: "18px",
                }}
              >
                Subscription Active
              </Typography>
            </Stack>
          </Stack>
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
        >
          <Box>
            <Button
              variant="outlined"
              size="small"
              sx={{
                color: "#353535CC",
                border: "1px solid #353535CC !important",
                textTransform: "none",
              }}
              onClick={toggleCancel}
            >
              <DoDisturb sx={{ color: "#353535CC", mr: 1 }} /> Cancel
              Subscription
            </Button>
          </Box>
          <Box>
            <Button
              variant="outlined"
              size="small"
              sx={{
                color: "#E25050",
                border: "1px solid #E25050 !important",
                textTransform: "none",
              }}
              onClick={toggleOpen}
            >
              <Delete sx={{ color: "#E25050", mr: 1 }} /> Delete Account
            </Button>
          </Box>
        </Stack>
      </Stack>
      <Dialog open={open} onClose={toggleOpen}>
        <DialogContent>
          <Stack alignItems="center" justifyContent="center" spacing={3}>
            <HighlightOffIcon sx={{ color: "#E25050", fontSize: "60px" }} />
            <Typography variant="h6">Are you sure to delete?</Typography>
            {loading ? (
              <TailSpinLoader />
            ) : (
              <Button
                variant="contained"
                sx={{ textTransform: "none", background: "#E25050 !important" }}
                onClick={handleDelete}
              >
                Delete
              </Button>
            )}
          </Stack>
        </DialogContent>
      </Dialog>
      <Dialog open={modal} onClose={toggleCancel}>
        <DialogContent>
          <Stack alignItems="center" justifyContent="center" spacing={3}>
            <CancelIcon sx={{ color: "#353535CC", fontSize: "60px" }} />
            <Typography variant="h6">Are you sure to Cancel?</Typography>

            {loading ? (
              <TailSpinLoader />
            ) : (
              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  background: "#353535CC !important",
                }}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            )}
          </Stack>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default InfoSection;
