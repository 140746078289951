import React, { useState } from "react";
import { Button, Dialog, DialogContent } from "@mui/material";
import LoginLogo from "../../assets/images/login.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import Repo from "../../repo/Repo";
import TailSpinLoader from "../../components/TailSpinLoader";

function ForgotPassword({ open, onClose }) {
  const [state, setState] = useState({
    forgotEmail: "",
    loading: "",
    error: false,
  });
  const handleInput = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleForgot = async () => {
    setState((prev) => ({ ...prev, loading: true }));
    let { data } = await Repo.email({
      request: {
        method: "sendForgetPasswordLink",
        data: {
          to: state.forgotEmail,
        },
      },
    });
    onClose();
  };
  const toggle = () => {
    if (!state.loading) {
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={toggle} fullWidth>
      <DialogContent>
        <div className="flex flex-col justify-center items-center relative">
          <img src={LoginLogo} alt="mail sent" className="w-10" />
          <span className="font-medium text-gray-900 text-lg mb-6">
            Reset Password
          </span>

          <div className="bg-gray-300 rounded flex w-full mb-5">
            <div className="input-bg rounded py-3 px-5">
              <FontAwesomeIcon icon={faEnvelope} className="text-white" />
            </div>
            <input
              type="text"
              name="forgotEmail"
              placeholder="email"
              value={state.forgotEmail}
              onChange={handleInput}
              className="outline-none bg-transparent px-4 w-full"
            />
          </div>

          <div className="w-full">
            {state.loading ? (
              <TailSpinLoader />
            ) : (
              <div className="flex space-x-2 justify-end">
                <Button className="text-xs" variant="outlined" onClick={toggle}>
                  Cancel
                </Button>{" "}
                <Button variant="contained" id="btn" onClick={handleForgot}>
                  Submit
                </Button>
              </div>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ForgotPassword;
