import { Button, Typography, styled } from "@mui/material";

export const BtnSubscribed = styled(Button)(({ theme }) => ({
  background: "#1D6788 !important",
  borderRadius: "6px",
  color: "white",
  fontFamily: "Urbanist",
  textTransform: "none",
  fontSize: "14px",
  [theme.breakpoints.down("md")]: {
    fontSize: "12px",
  },
}));
export const Title = styled(Typography)({
  fontWeight: 600,
  fontSize: "30px",
  color: "#034F75",
  textAlign: "center",
  fontFamily: "Raleway",
  paddingTop: "20px",
});
