import React, { useState } from "react";
import { Button, Dialog, IconButton, InputAdornment } from "@mui/material";
import { DialogContent, Stack, TextField, Typography } from "@mui/material";
import LoginLogo from "../../../assets/images/login.png";
import Toast from "../../../components/Toast";
import { email, singUp } from "../api";
import TailSpinLoader from "../../../components/TailSpinLoader";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ReCAPTCHA from "react-google-recaptcha";

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

function Signup({ open, onClose }) {
  const [showPass, setShowPass] = useState(false);
  const [showConPass, setShowConPass] = useState(false);
  const [state, setState] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    loading: false,
  });
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [checkCaptcha, setCheckCaptcha] = useState(false);
  console.log("checkCaptcha:", checkCaptcha);

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
    setCheckCaptcha(false);
  };

  const toggle = (name) => {
    if (name == "showPass") {
      setShowPass((show) => !show);
    } else {
      setShowConPass((show) => !show);
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    if (!state.email) {
      Toast("info", "Kindly enter email");
      return;
    }
    if (!emailRegex.test(state.email)) {
      Toast("info", "Invalid email");
      return;
    }
    if (!state.password) {
      Toast("info", "Kindly enter password");
      return;
    }
    if (!state.confirmPassword) {
      Toast("info", "Kindly enter confirm password");
      return;
    }
    if (state.password !== state.confirmPassword) {
      Toast("info", "Password not matching");
      return;
    }
    setState((prev) => ({ ...prev, loading: true }));
    let res = await singUp(state.email, state.password);
    if (res.response.data.userId) {
      let res = await email(state.email);
      if (res.response.data.success) {
        Toast("success", "Confirmation Email is Sent");
        onClose();
      }
    } else if (res.response.status.statusCode === 409) {
      Toast("error", "Email Already Exists");
      setState((prev) => ({ ...prev, loading: false }));
    } else {
      Toast("error", "Error");
      setState((prev) => ({ ...prev, loading: false }));
    }
  };

  const handleCaptcha = () => {
    if (!state.email) {
      Toast("info", "Kindly enter email");
      return;
    }
    if (!emailRegex.test(state.email)) {
      Toast("info", "Invalid email");
      return;
    }
    if (!state.password) {
      Toast("info", "Kindly enter password");
      return;
    }
    if (!state.confirmPassword) {
      Toast("info", "Kindly enter confirm password");
      return;
    }
    if (state.password !== state.confirmPassword) {
      Toast("info", "Password not matching");
      return;
    }
    setCheckCaptcha(true);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogContent>
        <Stack spacing={2} alignItems="center" justifyContent="center">
          <Stack alignItems="center">
            <img width="40px" src={LoginLogo} alt="" />
            <Typography
              variant="h6"
              sx={{ fontFamily: "Raleway", fontWeight: 700 }}
            >
              Signup
            </Typography>
          </Stack>
          <TextField
            fullWidth
            size="small"
            name="email"
            variant="outlined"
            placeholder="email"
            value={state.email}
            onChange={handleInput}
          />

          <TextField
            fullWidth
            size="small"
            name="password"
            variant="outlined"
            placeholder="Password"
            type={showPass ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => toggle("showPass")} edge="end">
                    {showPass ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={state.password}
            onChange={handleInput}
          />
          <TextField
            fullWidth
            size="small"
            name="confirmPassword"
            variant="outlined"
            placeholder="Confirm Password"
            type={showConPass ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => toggle("ConPass")} edge="end">
                    {showConPass ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={state.confirmPassword}
            onChange={handleInput}
          />
          {checkCaptcha && (
            <Stack my={1}>
              <ReCAPTCHA
                sitekey="6LevnzMoAAAAAHpufMyG2CPGdN5t1NTJF43t3pvO"
                onChange={handleRecaptchaChange}
              />
            </Stack>
          )}
          {!checkCaptcha && (
            <Stack width="100%" alignItems="center" spacing={1}>
              {state.loading ? (
                <TailSpinLoader />
              ) : (
                <Stack direction="row" justifyContent="center" spacing={2}>
                  <Button
                    size="small"
                    variant="outlined"
                    sx={{ textTransform: "none" }}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  {recaptchaValue ? (
                    <Button
                      size="small"
                      variant="contained"
                      sx={{ textTransform: "none" }}
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  ) : (
                    <Button
                      size="small"
                      variant="contained"
                      sx={{ textTransform: "none" }}
                      onClick={handleCaptcha}
                    >
                      Submit
                    </Button>
                  )}
                </Stack>
              )}
            </Stack>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default Signup;
