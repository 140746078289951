import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LoopLogo from "../../assets/images/Logo.png";
import { Box, Button, Grid, Stack, styled, Typography } from "@mui/material";
import DataSaverOnIcon from "@mui/icons-material/DataSaverOn";
import AdminHeader from "../../layout/user header";
import Footer from "./Footer";
import ReactGA from "../../Analytics";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../Admin/userSlice";
import Feedback from "./Feedback";

export const RootStyle = styled(Box)({
  background: "linear-gradient(90deg, #186ED0 0%, #17CEAD 100%)",
  padding: "2% 5%",
  minHeight: "100vh",
});

function Home() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lastVisited = localStorage.getItem("lastVisited");
  const { userExist, user } = useSelector((state) => state.user);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: "Home Page",
    });

    if (!userExist) {
      dispatch(getUser());
    }

    if (lastVisited) {
      navigate("/chatbot/details");
    }
  }, []);

  return (
    <RootStyle>
      <AdminHeader />

      <Stack alignItems="center">
        <img src={LoopLogo} alt="logo" width="241px" height="260px" />
        <Stack alignItems="center" height="100%" spacing={5}>
          <Typography
            sx={{
              color: "#353535",
              fontWeight: 900,
              fontSize: { md: "40px", xs: "18px" },
            }}
          >
            My Chat Bots
          </Typography>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item md={12}>
              <Typography
                sx={{
                  color: "#F2F2F2",
                  fontWeight: 300,
                  lineHeight: "30px",
                  fontSize: { md: "20px", xs: "14px" },
                  font: "Raleway",
                  textAlign: "center",
                }}
              >
                Chatbot Limit : {user?.subscription?.uploadLimit}{" "}
                {user?.subscription?.subType == "free"
                  ? "( upgrade subscription for more )"
                  : ""}
              </Typography>
            </Grid>
          </Grid>
          <Link to="/create-chatbot">
            <Button variant="contained">
              <DataSaverOnIcon sx={{ mx: 1 }} />
              Create Chatbot
            </Button>
          </Link>
        </Stack>
      </Stack>
      <Feedback />
      <Footer />
    </RootStyle>
  );
}

export default Home;
