import React, { useState } from "react";
import { Button, Stack, Typography, styled } from "@mui/material";
import hobbyIcon from "../../../assets/images/hobbyIcon.svg";
import DoneIcon from "@mui/icons-material/Done";
import { IconBox, RootStyle, SubTitle } from "./styles";
import { useSelector } from "react-redux";
import { BtnSubscribed } from "../style";
import UpdatePackage from "./UpdatePackage";

const BtnChoose = styled(Button)({
  background: "#E8F3FF !important",
  border: "1px solid #4BA3FA",
  borderRadius: "6px",
  textTransform: "none",
  color: "#4BA3FA",
});

function Hobby({ onClick }) {
  const { isLoggedIn, user } = useSelector((state) => state.user);
  let type = user?.subscription?.subType;
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen((prev) => !prev);
  };

  const handleChoose = () => {
    if (isLoggedIn) {
      type != "free" ? toggle() : onClick();
    } else {
      onClick();
    }
  };

  return (
    <RootStyle>
      <Stack>
        <Stack
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
        >
          <IconBox>
            <img width="22px" height="22px" src={hobbyIcon} alt="" />
          </IconBox>
          <Stack>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: 500,
                fontSize: "12px",
                color: "#C8C8C8",
                textAlign: "center",
              }}
            >
              PLAN TYPE
            </Typography>
            <Typography
              sx={{
                fontFamily: "Raleway",
                fontWeight: 700,
                fontSize: { xs: "16px", md: "20px" },
                color: "#353535",
                textAlign: "center",
              }}
            >
              Hobby
            </Typography>
          </Stack>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontWeight: 600,
              fontSize: { xs: "16px", md: "20px" },
              color: "#4BA3FA",
            }}
          >
            $9
          </Typography>
        </Stack>

        <Stack alignItems="center" justifyContent="center">
          <Typography
            sx={{
              fontFamily: "Urbanist",
              fontWeight: 600,
              fontSize: "14px",
              color: "#4BA3FA",
            }}
          >
            Billed Monthly
          </Typography>
        </Stack>

        <Stack mt={1}>
          <Stack direction="row" spacing={1}>
            <DoneIcon sx={{ color: "#4BA3FA" }} />
            <SubTitle sx={{ color: "#828282" }}>
              2,000
              <span style={{ marginLeft: "5px", fontWeight: 500 }}>
                message credits /month.
              </span>
            </SubTitle>
          </Stack>
          <Stack direction="row" spacing={1}>
            <DoneIcon sx={{ color: "#4BA3FA" }} />
            <SubTitle sx={{ color: "#828282" }}>
              Unlimited
              <span style={{ marginLeft: "5px", fontWeight: 500 }}>
                chatbots.
              </span>
            </SubTitle>
          </Stack>
          <Stack direction="row" spacing={1}>
            <DoneIcon sx={{ color: "#4BA3FA" }} />
            <SubTitle sx={{ color: "#828282" }}>
              1,000,000
              <span style={{ marginLeft: "5px", fontWeight: 500 }}>
                characters / chatbot.
              </span>
            </SubTitle>
          </Stack>
          <Stack direction="row" spacing={1}>
            <DoneIcon sx={{ color: "#4BA3FA" }} />
            <SubTitle sx={{ color: "#828282" }}>
              Embed
              <span style={{ marginLeft: "5px", fontWeight: 500 }}>
                on unlimited websites.
              </span>
            </SubTitle>
          </Stack>
          <Stack direction="row" spacing={1}>
            <DoneIcon sx={{ color: "#4BA3FA" }} />
            <SubTitle sx={{ color: "#828282" }}>
              Upload
              <span style={{ marginLeft: "5px", fontWeight: 500 }}>
                two files.
              </span>
            </SubTitle>
          </Stack>
          <Stack direction="row" spacing={1}>
            <DoneIcon sx={{ color: "#4BA3FA" }} />
            <SubTitle sx={{ color: "#828282" }}>
              API
              <span style={{ marginLeft: "5px", fontWeight: 500 }}>Access</span>
            </SubTitle>
          </Stack>
          <Stack direction="row" spacing={1}>
            <DoneIcon sx={{ color: "#4BA3FA" }} />
            <SubTitle sx={{ color: "#828282" }}>
              Share
              <span style={{ marginLeft: "5px", fontWeight: 500 }}>
                chatbot with everyone through a simple link.
              </span>
            </SubTitle>
          </Stack>
        </Stack>
      </Stack>
      <Stack justifyContent="center" alignItems="center">
        {user?.subscription?.subType === "hobby" ? (
          <BtnSubscribed variant="contained" fullWidth>
            Subscribed
          </BtnSubscribed>
        ) : (
          <BtnChoose fullWidth variant="contained" onClick={handleChoose}>
            Choose Plan
          </BtnChoose>
        )}
      </Stack>
      {open && <UpdatePackage open={open} onClose={toggle} title="hobby" />}
    </RootStyle>
  );
}

export default Hobby;
