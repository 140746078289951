import { Typography, styled } from "@mui/material";

export const Title = styled(Typography)(({ theme }) => ({
  fontFamily: "Raleway",
  fontWeight: 500,
  fontSize: "24px",
  [theme.breakpoints.down("md")]: {
    fontSize: "15",
  },
}));
