import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Repo from "../../repo/Repo";

const initialState = {
  loading: false,
  error: "",
  chatbot: "",
  newFiles: [],
};

export const getChatbot = createAsyncThunk("bot/fetchBot", (arg) => {
  return Repo.chatbot({
    request: {
      method: "getChatBot",
      data: {
        _id: arg,
      },
    },
  })
    .then(({ data }) => data.response.data.chatbot)
    .catch((err) => console.log(err));
});

export const chatbotSlice = createSlice({
  name: "chatbot",
  initialState,
  reducers: {
    updateChatbot: (state, action) => {
      state.chatbot = action.payload;
    },
    selectChatbot: (state, action) => {
      state.chatbot = action.payload;
      state.newFiles = [];
    },
    fileAdded: (state, action) => {
      let file = action.payload;
      state.newFiles.push(file);
    },
    fileRemoved: (state, action) => {
      let index = action.payload;
      state.newFiles.splice(index, 1);
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getChatbot.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getChatbot.fulfilled, (state, action) => {
      state.chatbot = action.payload;
      state.newFiles = [];
      state.loading = false;
      state.error = "";
    });
    builder.addCase(getChatbot.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { updateChatbot, selectChatbot, fileAdded, fileRemoved } =
  chatbotSlice.actions;

export default chatbotSlice.reducer;
