import React, { useEffect, useState } from "react";
import AdminHeader from "../../layout/user header";
import { Box, Grid } from "@mui/material";
import { Button, Container, Stack, Typography, styled } from "@mui/material";
import DataSaverOnIcon from "@mui/icons-material/DataSaverOn";
import Pic from "../../assets/images/pic.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Repo from "../../repo/Repo";
import moment from "moment";
import TailSpinLoader from "../../components/TailSpinLoader";
import Toast from "../../components/Toast";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../Admin/userSlice";
import Footer from "../home/Footer";
import ReactGA from "../../Analytics";
import { selectChatbot } from "./chatbotSlice";
import Feedback from "../home/Feedback";

const TypoStyle = styled(Typography)({
  color: "rgba(53, 53, 53, 1)",
});
const RootStyle = styled(Box)({
  background: "linear-gradient(90deg, #186ED0 0%, #17CEAD 100%)",
  padding: "2% 5%",
  minHeight: "100vh",
});

function Chatbots() {
  let email = localStorage.getItem("email");
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [chatbots, setChatbots] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (email) {
      getChatbots();
    }
    if (user == "") {
      dispatch(getUser());
    }

    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: "My Chatbots",
    });
  }, []);

  const getChatbots = async () => {
    setLoading(true);
    try {
      let { data } = await Repo.chatbot({
        request: {
          method: "getChatBotsByEmail",
          data: {
            email: localStorage.getItem("email"),
          },
        },
      });

      if (data.response.status.statusCode == 200) {
        setChatbots(data.response.data.chatbotsArr);
      } else {
        Toast("error", "Erro");
      }
      setLoading(false);
    } catch (err) {
      console.log("err:", err);
      setLoading(false);
      Toast("error", "Erro");
    }
  };

  const handleSelect = (item) => {
    dispatch(selectChatbot(item));
    navigate("/chatbot/details", { state: { botId: item._id } });
  };

  return (
    <RootStyle>
      <AdminHeader />
      <Container>
        <Stack minHeight="85vh" justifyContent="space-between">
          <Stack>
            <Stack
              justifyContent="space-between"
              direction={{ md: "row", xs: "column" }}
              sx={{ padding: "30px" }}
            >
              <Typography
                sx={{
                  fontFamily: "Raleway",
                  fontWeight: 700,
                  color: "#353535",
                  fontSize: "24px",
                }}
              >
                My ChatBots
              </Typography>
              <Typography>
                Chatbot Limit : {user?.subscription?.uploadLimit}{" "}
                {user?.subscription?.subType == "free"
                  ? "( upgrade subscription for more )"
                  : ""}
              </Typography>
              <Link to="/create-chatbot">
                <Button
                  variant="contained"
                  sx={{
                    background: "#2677D9 !important",
                    borderRadius: "4px",
                    textTransform: "none",
                  }}
                >
                  <DataSaverOnIcon sx={{ mx: 1 }} />
                  Create New Bot
                </Button>
              </Link>
            </Stack>
            <Stack direction="row" mb={1} spacing={3}>
              <Typography>
                Characters Limit : {user?.subscription?.totalCharLimit}
              </Typography>
              <Typography>
                Left Characters : {user?.subscription?.charLimit}
              </Typography>
            </Stack>
            <Grid container columnSpacing={5} rowSpacing={2}>
              {chatbots.map((item, i) => {
                return (
                  <Grid item md={4} sm={6} xs={12} key={i}>
                    <Box
                      sx={{
                        background: "rgba(255, 255, 255, 0.1)",
                        padding: "16px 32px 16px 16px",
                        borderRadius: "6px",
                        boxShadow: "0px 4px 4px rgba(57, 58, 55, 0.2)",
                      }}
                    >
                      <Box
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleSelect(item)}
                      >
                        <Stack direction="row" justifyContent="space-between">
                          <img src={Pic} alt="" />
                          <Stack ml={1}>
                            <Typography>{item?.botName}</Typography>
                            <TypoStyle>
                              Characters : {item?.characters}
                            </TypoStyle>
                            <TypoStyle>Created : </TypoStyle>
                            <TypoStyle>
                              {moment
                                .unix(item.created_at)
                                .format("DD-MM-YYYY")}
                            </TypoStyle>
                            <TypoStyle>
                              {moment.unix(item.created_at).format("hh:mm A")}
                            </TypoStyle>
                          </Stack>
                        </Stack>
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>

            {loading && <TailSpinLoader loading={loading} />}
          </Stack>
          <Footer />
        </Stack>
      </Container>
      <Feedback />
    </RootStyle>
  );
}

export default Chatbots;
