import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, IconButton, Stack } from "@mui/material";
import { InputAdornment, styled, TextField, Typography } from "@mui/material";
import Logo from "../../assets/images/Logo.png";
import passIcon from "../../assets/images/password icon.svg";
import gmailIcon from "../../assets/images/gmail icon.png";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Toast from "../../components/Toast";
import TailSpinLoader from "../../components/TailSpinLoader";
import Repo from "../../repo/Repo";
import { useDispatch } from "react-redux";
import { userAdd, userLogged } from "./userSlice";
import ReactGA from "../../Analytics";

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/;

const RootStyle = styled(Box)({
  background: "linear-gradient(90deg, #186ED0 0%, #17CEAD 100%)",
  padding: "2% 5%",
  minHeight: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    username: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: "Admin Login",
    });
  }, []);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };
  const toggle = () => setShowPassword((show) => !show);

  const handleLogin = async () => {
    if (!state.username) {
      Toast("info", "Enter email");
      return;
    }
    if (!state.password) {
      Toast("info", "Enter password");
      return;
    }
    if (emailRegex.test(state.username)) {
      Toast("info", "Invalid Email");
      return;
    }
    setLoading(true);
    let { data } = await Repo.user({
      request: {
        method: "loginUser",
        data: {
          email: state.username,
          password: state.password,
          admin: true,
        },
      },
    });
    if (data.response?.status?.statusCode == 200) {
      localStorage.setItem("loggedIn", true);
      localStorage.setItem("email", data.response.data.userObj?.email);
      dispatch(userAdd(data.response.data.userObj));
      dispatch(userLogged("admin"));
      setLoading(false);
      navigate("/");
    } else {
      Toast("info", "invalid credientials");
      setLoading(false);
    }
  };

  return (
    <RootStyle>
      <Box sx={{ background: "#FFFFFF1A", width: { xs: "90%", md: "50%" } }}>
        <Stack
          my={5}
          px={{ xs: "5%", md: "15%" }}
          spacing={5}
          alignItems="center"
        >
          <Stack alignItems="center">
            <img height="118px" width="109px" src={Logo} alt="" />
            <Typography
              sx={{
                fontFamily: "Raleway",
                fontWeight: 700,
                fontSize: "24px",
                color: "#353535",
              }}
            >
              Adming Login
            </Typography>
          </Stack>

          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="example@example.com"
            name="username"
            sx={{
              borderRadius: "4px",
              background: "#F2F2F2",
              border: "none",
              "& fieldset": { padding: "0px", border: "none" },
            }}
            autoComplete="off"
            InputProps={{
              style: {
                padding: "0",
              },
              startAdornment: (
                <InputAdornment position="start">
                  <Box
                    sx={{
                      width: "70px",
                      height: "40px",
                      borderRadius: "4px",
                      background:
                        "linear-gradient(135deg, #17CDAD 0%, #186FD0 100%)",
                    }}
                  >
                    <Stack alignItems="center" justifyContent="center">
                      <img src={gmailIcon} alt="" />
                    </Stack>
                  </Box>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment sx={{ mr: 2 }} position="end">
                  <CancelOutlinedIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      setState((prev) => ({ ...prev, username: "" }))
                    }
                  />
                </InputAdornment>
              ),
            }}
            value={state.username}
            onChange={handleInput}
          />
          <Stack width="100%" spacing={1}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              placeholder="password"
              name="password"
              type={showPassword ? "text" : "password"}
              sx={{
                borderRadius: "4px",
                background: "#F2F2F2",
                border: "none",
                "& fieldset": { padding: "0px", border: "none" },
              }}
              autoComplete="off"
              InputProps={{
                style: {
                  padding: "0",
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <Box
                      sx={{
                        width: "70px",
                        height: "40px",
                        borderRadius: "4px",
                        background:
                          "linear-gradient(135deg, #17CDAD 0%, #186FD0 100%)",
                      }}
                    >
                      <Stack alignItems="center" justifyContent="center">
                        <img src={passIcon} alt="" />
                      </Stack>
                    </Box>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment sx={{ mr: 2 }} position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={toggle}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={state.password}
              onChange={handleInput}
            />
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Typography
                sx={{
                  fontFamily: "Raleway",
                  fontSize: "12px",
                  fontWeight: 600,
                  color: "#2677D9",
                }}
              >
                forgot password?
              </Typography>
            </Box>
          </Stack>
          {loading ? (
            <TailSpinLoader />
          ) : (
            <Button
              variant="contained"
              sx={{ textTransform: "none" }}
              onClick={handleLogin}
            >
              Access
            </Button>
          )}
        </Stack>
      </Box>
    </RootStyle>
  );
};

export default Login;
