import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: "",
  loading: false,
  progress: 1,
  value: 0,
  title: "",
  allFiles: [],
  text: "",
  urls: [],
  subtitles: "",
  counts: [],
  limitWarning: {
    modal: false,
    currentChars: "",
    leftChars: "",
  },
};

export const createbotSlice = createSlice({
  name: "createbot",
  initialState,
  reducers: {
    addFile: (state, action) => {
      state.allFiles.push(action.payload);
    },
    removeFile: (state, action) => {
      let { index } = action.payload;
      state.allFiles.splice(index, 1);
    },
    fileCounts: (state, action) => {
      state.counts = action.payload;
    },
    addText: (state, action) => {
      state.text = action.payload;
    },
    addTitle: (state, action) => {
      state.title = action.payload;
    },
    clearUrls: (state) => {
      state.urls = [];
    },
    addUrls: (state, action) => {
      state.urls = action.payload;
    },
    startLoading: (state, action) => {
      state.loading = true;
    },
    stopLoading: (state, action) => {
      state.loading = false;
    },
    addSubtitles: (state, action) => {
      state.subtitles = action.payload;
    },
  },
});

export const {
  startLoading,
  stopLoading,
  addUrls,
  clearUrls,
  addTitle,
  addText,
  addFile,
  fileCounts,
  removeFile,
  addSubtitles,
} = createbotSlice.actions;

export default createbotSlice.reducer;
