import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { Stepper, Step, StepLabel } from "@mui/material";
import { Divider, Grid, LinearProgress, Stack, Tab } from "@mui/material";
import { Tabs, TextField, Typography, styled } from "@mui/material";
import File from "./Files";
import Website from "./Website";
import Texts from "./Text";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { useLocation, useNavigate } from "react-router-dom";
import LimitWarning from "./LimitWarning";
import { useDispatch, useSelector } from "react-redux";
import ReactGA from "../../Analytics";
import AdminHeader from "../../layout/user header";
import { getUser } from "../Admin/userSlice";
import Toast from "../../components/Toast";
import Footer from "../home/Footer";
import Feedback from "../home/Feedback";
import Youtube from "./Youtube";
import Drive from "./Drive";
import { addTitle, startLoading, stopLoading } from "./createbotSlice";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ mt: 3 }}>{children}</Box>}
    </div>
  );
}

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor:
        theme.palette.mode === "dark"
          ? theme.palette.grey[800]
          : theme.palette.grey[800],
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor:
        theme.palette.mode === "dark"
          ? theme.palette.grey[800]
          : theme.palette.grey[800],
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark"
        ? theme.palette.grey[800]
        : theme.palette.grey[800],
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));
const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  border: "1.5px solid rgba(53, 53, 53, 1)",
  zIndex: 1,
  color: "#000",
  width: 30,
  height: 30,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    background: "#000",
    color: "#FFF",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    background: "#000",
    color: "#FFF",
  }),
}));
function ColorlibStepIcon(props) {
  const { active, completed, className } = props;
  const icons = [0, 1, 2, 3, 4, 5];
  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}
const RootStyle = styled(Box)({
  background: "linear-gradient(90deg, #186ED0 0%, #17CEAD 100%)",
  padding: "2% 5%",
  minHeight: "100vh",
});

const MyTab = styled(Tab)({
  fontFamily: "Raleway",
  fontWeight: 600,
  fontSize: "10.5px",
});

const steps = ["Files", "Text", "Website", "Youtube", "Drive"];

function CreateChatbot() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { loading, title, allFiles, text, urls, counts, subtitles } =
    useSelector((state) => state.createbot);
  const [progress, setProgress] = useState(1);
  const [value, setValue] = useState(0);
  const [limitWarning, setLimitWarning] = useState({
    modal: false,
    currentChars: "",
    leftChars: "",
  });

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: "Create Chatbot",
    });
  }, []);

  const handleCreate = () => {
    if (!title) {
      Toast("info", "Add a chatbot name");
      return;
    }
    if (urls.length == 0 && text == "" && allFiles.length == 0 && !subtitles) {
      Toast("info", "Kindly Add Text or Links or Files");
      return;
    }
    if (urls.length > 20) {
      Toast("info", "Only 20 links are allowed in Free Plan");
      return;
    }

    dispatch(startLoading());

    const timer = setInterval(() => {
      setProgress((prev) => (prev >= 100 ? 1 : prev + 1));
    }, 500);
    let myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer V78L3fNtjQ7quJ94h8YVSg==1VYOuAp34mHUAbPp"
    );
    let formdata = new FormData();
    if (allFiles.length > 0) {
      allFiles.forEach((item) => {
        formdata.append("files", item[0]);
      });
    } else {
      formdata.append("files", "");
    }
    formdata.append("email", localStorage.getItem("email"));
    formdata.append("botName", title);
    formdata.append("urls", urls);
    formdata.append("text", text);
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch("https://api.urhja.com/create_chatbot", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        setProgress(1);
        dispatch(stopLoading());
        let res = JSON.parse(result);
        clearInterval(timer);
        if (res.chatBotId) {
          Toast("success", "Chatbot Created Successfully");
          dispatch(getUser());
          navigate("/chatbots");
        } else {
          if (res.error == "Not Created:Character limit exceeds!") {
            setLimitWarning({
              modal: true,
              currentChars: res?.characters,
              leftChars: res?.charactersLeft,
            });
          } else {
            Toast("error", res.error);
          }
        }
      })
      .catch((error) => {
        dispatch(stopLoading());
        setProgress(1);
        clearInterval(timer);
        console.log("error", error);
        Toast("error", error);
      });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleInput = (e) => {
    const { value } = e.target;
    dispatch(addTitle(value));
  };

  const charsCount = (e) => {
    let count = 0;
    if (text) {
      count += text.length;
    }
    if (counts.length > 0) {
      counts.forEach((i) => {
        count += i;
      });
    }
    return count || 0;
  };

  const warnToggle = () => {
    setLimitWarning({
      modal: false,
      currentChars: "",
      leftChars: "",
    });
  };

  return (
    <RootStyle>
      <AdminHeader />
      <Grid container sx={{ mt: 2 }} spacing={2}>
        <Grid item md={3} xs={12}>
          <Stack width="100%" spacing={2}>
            <TextField
              fullWidth
              size="small"
              sx={{
                color: "rgba(53, 53, 53, 0.5)",
                background: "rgba(255, 255, 255, 0.1)",
                borderRadius: "6px",
                border: "none",
                "& fieldset": { border: "none" },
              }}
              value={title}
              placeholder="chatbot name here "
              onChange={handleInput}
            />
            <Typography variant="h6">Included Data :</Typography>
            <Typography sx={{ color: "rgba(53, 53, 53, 1)" }}>
              File count ( {allFiles?.length || 0} )
            </Typography>
            <Typography sx={{ color: "rgba(53, 53, 53, 1)" }}>
              Text Input characters ( {text.length || 0})
            </Typography>
            <Typography sx={{ color: "rgba(53, 53, 53, 1)" }}>
              links ( {urls.length || 0} )
            </Typography>
            <Divider />
            <Typography sx={{ color: "rgba(53, 53, 53, 1)" }}>
              Total characters - {charsCount()}
            </Typography>
          </Stack>
        </Grid>
        <Grid item md={6} xs={12}>
          <Tabs
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.05)",
              borderRadius: "6px",
            }}
            textColor="rgba(53, 53, 53, 1)"
            variant="standard"
            value={value}
            onChange={handleChange}
            centered
          >
            <MyTab label="Files" />
            <MyTab label="Text" />
            <MyTab label="Website" />

            {/* will be used */}

            {/* <MyTab label="Youtube" />
            <MyTab label="Drive" /> */}
          </Tabs>
          <TabPanel value={value} index={0}>
            <File />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Texts />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Website />
          </TabPanel>

          {/* will be used */}

          {/* <TabPanel value={value} index={3}>
            <Youtube />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <Drive />
          </TabPanel> */}
          <Stack width="100%" alignItems="center" justifyContent="center">
            <Box sx={{ height: "30px", mb: 1, width: "100%" }}>
              {loading && (
                <>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                  >{`${Math.round(progress)}%`}</Typography>
                  <Box
                    sx={{
                      height: "6px",
                      background: "rgba(242, 242, 242, 0.2)",
                    }}
                  >
                    <LinearProgress
                      sx={{ height: "6px" }}
                      variant="determinate"
                      value={progress}
                    />
                  </Box>
                </>
              )}
            </Box>

            <Button
              disabled={loading ? true : false}
              variant="contained"
              onClick={handleCreate}
            >
              Create new Bot
            </Button>
          </Stack>
        </Grid>
        <Grid item md={3} xs={12}>
          <Box sx={{ width: "100%" }}>
            <Stepper
              alternativeLabel
              activeStep={value}
              connector={<QontoConnector />}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            <Typography
              sx={{ mt: 4 }}
              alignItems="center"
              justifyContent="center"
            >
              Make sure to add data in at least one section to create your
              chatbot.
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Footer />
      <Feedback />
      {limitWarning.modal && (
        <LimitWarning info={limitWarning} onClose={warnToggle} />
      )}
    </RootStyle>
  );
}

export default CreateChatbot;
