import React, { useState } from "react";
import { Button } from "@mui/material";
import { Dialog, DialogContent, Stack, Typography } from "@mui/material";
import TailSpinLoader from "../../../components/TailSpinLoader";
import UpdateIcon from "@mui/icons-material/Update";
import Repo from "../../../repo/Repo";
import { useDispatch } from "react-redux";
import Toast from "../../../components/Toast";
import SuccessIcon from "../../../assets/images/success icon.svg";
import { getUser } from "../../Admin/userSlice";

function UpdatePackage({ title, open, onClose }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [updated, setUpdated] = useState(false);

  const handleUpdate = async () => {
    try {
      setLoading(true);
      let { data } = await Repo.subscribe({
        request: {
          method: "updatePackage",
          data: {
            email: localStorage.getItem("email"),
            package: title,
          },
        },
      });
      console.log("data:", data);
      if (data.response.status.statusCode == 200) {
        Toast("success", "Package Update Successfully");
        setLoading(false);
        setUpdated(true);
        dispatch(getUser());
      } else {
        Toast("error", "Package Not Update");
        setLoading(false);
        onClose();
      }
    } catch (err) {
      console.log("err:", err);
      onClose();
      setLoading(false);
    }
  };

  const toggle = () => {
    if (!loading) {
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={toggle} fullWidth maxWidth="xs">
      <DialogContent>
        {updated ? (
          <Stack alignItems="center" justifyContent="center" spacing={2}>
            <img src={SuccessIcon} alt="" />
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={1}
            >
              <Typography>Updated to</Typography>
              <Typography variant="h4" sx={{ textTransform: "capitalize" }}>
                {title}
              </Typography>
            </Stack>

            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                background: "#353535CC !important",
              }}
              onClick={toggle}
            >
              Close
            </Button>
          </Stack>
        ) : (
          <Stack alignItems="center" justifyContent="center" spacing={2}>
            <UpdateIcon sx={{ color: "#353535CC", fontSize: "60px" }} />
            <Stack alignItems="center" justifyContent="center">
              <Typography>Are you sure to update to</Typography>
              <Typography variant="h4" sx={{ textTransform: "capitalize" }}>
                {title}
              </Typography>
            </Stack>

            {loading ? (
              <TailSpinLoader />
            ) : (
              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  background: "#353535CC !important",
                }}
                onClick={handleUpdate}
              >
                Update
              </Button>
            )}
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default UpdatePackage;
