import React from "react";
import { Grid, Stack, TextareaAutosize, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { addText } from "./createbotSlice";
import { Title } from "./styles";

function Text() {
  const dispatch = useDispatch();
  const { text } = useSelector((state) => state.createbot);

  const handleInput = (e) => {
    const { value } = e.target;
    dispatch(addText(value));
  };
  return (
    <Stack justifyContent="center" alignItems="center" spacing={4}>
      <Title>Add Text</Title>
      <Grid container justifyContent="center">
        <TextareaAutosize
          placeholder="Write text here ..."
          value={text}
          onChange={handleInput}
          style={{
            background: "rgba(255, 255, 255, 0.05)",
            borderRadius: "6px",
            padding: "10px",
            height: 230,
            border: 0,
            width: "100%",
            boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
            overflow: "auto",
          }}
        />
      </Grid>
    </Stack>
  );
}

export default Text;
