import { configureStore } from "@reduxjs/toolkit";
import userSlice from "../pages/Admin/userSlice";
import chatbotSlice from "../pages/mychatbot/chatbotSlice";
import createbotSlice from "../pages/create chatbot/createbotSlice";

export const store = configureStore({
  reducer: {
    user: userSlice,
    chatbot: chatbotSlice,
    createbot: createbotSlice,
  },
});
