import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "../../Analytics";
import AnalysisSection from "./AnalysisSection";
import ReviewSection from "./ReviewSection";
import SubscribeSection from "./SubscribeSection";
import FAQs from "./FAQs";
import HomeSection from "./HomeSection";
import { Box } from "@mui/material";
import { scroller } from "react-scroll";
import Footer from "../../layout/footer";
import Testimonials from "./Testimonials";
import EmbedChat from "../mychatbot/EmbedChat";

const LandingPage = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: "Landing Page",
    });
  }, []);

  const handleScroll = (name) => {
    scroller.scrollTo([name], {
      duration: 2000,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  return (
    <Box>
      <HomeSection onSuccess={(e) => handleScroll(e)} />
      <AnalysisSection />
      <ReviewSection />
      <Box id="pricing">
        <SubscribeSection />
      </Box>
      <Box id="faqs">
        <FAQs />
      </Box>
      <Testimonials />
      <Footer />

      <EmbedChat _id="87beccc1286b41c4bdb77ac5e0b7a139" />
    </Box>
  );
};

export default LandingPage;
