import React, { useState } from "react";
import {
  Box,
  Button,
  Rating,
  Stack,
  TextField,
  styled,
  Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import VerifiedIcon from "@mui/icons-material/Verified";
import FeedbackIcon from "@mui/icons-material/Feedback";
import Repo from "../../repo/Repo";
import moment from "moment";
import Toast from "../../components/Toast";
import TailSpinLoader from "../../components/TailSpinLoader";

const RootStyle = styled(Box)({
  bottom: 40,
  right: 40,
  position: "fixed",
  zIndex: 1,
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "end",
});
const ButtonBox = styled(Box)({
  background: "#333",
  height: "40px",
  width: "40px",
  borderRadius: "40px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

function Feedback() {
  const [show, setShow] = useState(false);
  const [stars, setStars] = useState("");
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleStars = (e) => {
    setStars(e);
  };

  const handleInput = (e) => {
    const { value } = e.target;
    setText(value);
  };

  const toggle = () => {
    setShow((prev) => !prev);
    if (submitted) {
      setSubmitted(false);
    }
  };

  const handleSubmit = async () => {
    if (!text && !stars) {
      Toast("info", "Enter Text or Stars");
      return;
    }
    setLoading(true);
    let { data } = await Repo.feedback({
      request: {
        method: "saveFeedback",
        data: {
          email: localStorage.getItem("email"),
          feedback: {
            rating: stars,
            message: text,
            date: moment().format("DD-MM-YYYY"),
          },
        },
      },
    });
    setLoading(false);
    if (data.response.status.statusCode == 200) {
      Toast("success", "Submitted Successfully");
      setText("");
      setStars("");
      setSubmitted(true);
    } else {
      Toast("error", "Feedback not Submitted");
    }
  };

  return (
    <RootStyle>
      {show ? (
        <Box
          sx={{
            borderRadius: "16px",
            minHeight: "300px",
            width: { xs: "95%", md: "300px" },
            background: "white",
            p: "5%",
          }}
        >
          <CancelIcon sx={{ mb: 3, cursor: "pointer" }} onClick={toggle} />
          {submitted ? (
            <Stack alignItems="center" justifyContent="center" spacing={3}>
              <VerifiedIcon sx={{ fontSize: "40px", color: "yellow" }} />
              <Typography variant="h6" sx={{ textAlign: "center" }}>
                Feedback Submitted Successfully
              </Typography>
              <Box>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ textTransform: "none" }}
                  onClick={toggle}
                >
                  Close
                </Button>
              </Box>
            </Stack>
          ) : (
            <Stack alignItems="center" justifyContent="center" spacing={3}>
              <Rating
                name="simple-controlled"
                value={stars}
                onChange={(event, newValue) => {
                  handleStars(newValue);
                }}
              />
              <TextField
                variant="outlined"
                placeholder="your feedback"
                multiline
                minRows={4}
                maxRows={4}
                fullWidth
                sx={{ borderRadius: "4px" }}
                value={text}
                onChange={handleInput}
              />
              <Box>
                {loading ? (
                  <TailSpinLoader />
                ) : (
                  <Button
                    variant="contained"
                    size="small"
                    sx={{ textTransform: "none" }}
                    onClick={handleSubmit}
                  >
                    <VerifiedIcon sx={{ fontSize: "17px", mr: 1 }} /> Submit
                  </Button>
                )}
              </Box>
            </Stack>
          )}
        </Box>
      ) : (
        <ButtonBox>
          <FeedbackIcon
            sx={{ color: "white", cursor: "pointer" }}
            onClick={toggle}
          />
        </ButtonBox>
      )}
    </RootStyle>
  );
}

export default Feedback;
