import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ReactGA from "../../Analytics";

const EmailVerification = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const email = new URLSearchParams(location.search).get("email");

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: "Email Verification",
    });
  }, []);

  const verifyEmail = () => {
    if (!email) {
      toast("Reopen the link. Something went wrong");
      return;
    }
    setLoading(true);
    axios
      .post("https://api.urhja.com/users", {
        request: {
          method: "updateUser",
          data: {
            _id: email,
            emailVerified: true,
          },
        },
      })
      .then((res) => {
        toast("Your email is verified. You can use your account now.");
        setLoading(false);
        navigate("/");
      })
      .catch((err) => {
        setLoading(false);

        toast("Something went wrong");
      });
  };

  return (
    <div className="h-screen w-full flex items-center justify-center">
      <div className="border shadow rounded w-fit p-5">
        <h1 className="text-lg font-medium">
          To verify your email press the verify button below
        </h1>
        <h1>Please wait for account activation.</h1>

        <button
          className="rounded bg-sky-300 text-blue-600 px-5 py-2 font-medium mx-auto block"
          onClick={verifyEmail}
        >
          {loading ? "Loading..." : "Verify"}
        </button>
      </div>
    </div>
  );
};

export default EmailVerification;
