import React, { useEffect } from "react";
import HomeSection from "../landing page/HomeSection";
import UpdatePassword from "./UpdatePassword";
import { useLocation } from "react-router-dom";
import ReactGA from "../../Analytics";

function ResetPassword() {
  const location = useLocation();
  const constant = () => {};

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: "Reset Password",
    });
  }, []);
  return (
    <div className="bg-gradient-to-r from-purple-500 to-pink-500 sm:px-28 px-6 py-5">
      <HomeSection />
      <UpdatePassword open={true} onClose={constant} />
    </div>
  );
}

export default ResetPassword;
