import { Navigate, Route, Routes } from "react-router-dom";
import { useEffect } from "react";
import LandingPage from "./pages/landing page";
import Home from "./pages/home";
import Details from "./pages/mychatbot/details";
import Subscription from "./pages/subscription";
import ChatWindow from "./pages/mychatbot/ChatWindow";
import { Box } from "@mui/material";
import EmbedChat from "./pages/mychatbot/EmbedChat";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "./pages/Admin/userSlice";
import Chatbots from "./pages/mychatbot";
import CreateChatbot from "./pages/create chatbot";
import EmailVerification from "./pages/EmailVerification/EmailVerification";
import Profile from "./pages/profile";
import ResetPassword from "./pages/Admin/ResetPassword";
import Login from "./pages/Admin/Login";
import Dashboard from "./admin pages/dashboard";
import ReactGA from "./Analytics";

function App() {
  const dispatch = useDispatch();
  const loggedIn = localStorage.getItem("loggedIn");
  const pathname = window.location.pathname;
  const { user, isLoggedIn } = useSelector((state) => state.user);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: pathname,
      title: "Talkitout",
    });
    if (loggedIn && user == "") {
      dispatch(getUser());
    }
  }, []);

  const UserRoutes = () => {
    return (
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/profile" element={<Profile />} />
        <Route exact path="/subscription" element={<Subscription />} />
        <Route exact path="/chatbot/details" element={<Details />} />
        <Route exact path="/chatbots" element={<Chatbots />} />
        <Route exact path="/create-chatbot" element={<CreateChatbot />} />
        <Route exact path="/chat/:id" element={<ChatWindow />} />
        <Route exact path="/embed-chat/:id" element={<EmbedChat />} />
        <Route exact path="/demo" element={<LandingPage />} />
        <Route path="*" element={<Home />} />
      </Routes>
    );
  };
  const AdminRoutes = () => {
    return (
      <Routes>
        <Route exact path="/" element={<Dashboard />} />
        <Route path="*" element={<Dashboard />} />
      </Routes>
    );
  };

  return (
    <Box>
      {!isLoggedIn && (
        <Routes>
          <Route exact path="/admin" element={<Login />} />
          <Route exact path="/" element={<LandingPage />} />
          <Route path="/email-verification" element={<EmailVerification />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route exact path="/chat/:id" element={<ChatWindow />} />
          <Route exact path="/embed-chat/:id" element={<EmbedChat />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      )}
      {isLoggedIn == "user" && (
        <Routes>
          <Route path="/*" element={<UserRoutes />} />
        </Routes>
      )}
      {isLoggedIn == "admin" && (
        <Routes>
          <Route path="/*" element={<AdminRoutes />} />
        </Routes>
      )}
    </Box>
  );
}

export default App;
