import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Stack, Typography, styled } from "@mui/material";
import LoopLogo from "../../assets/images/Logo.png";
import { ButtonFAQs, ButtonLogin, ButtonPricing } from "../user header/style";

const RootStyle = styled(Box)({
  backdropFilter: "blur(1.5px)",
  borderRadius: "6px",
  height: "53px",
});
const MenuBox = styled(Box)(({ theme }) => ({
  zIndex: 1,
  background: "white",
  borderRadius: "0 0 6px 6px",
  minHeight: "110px",
  flexDirection: "column",
  display: "none",
  gap: "20px",
  padding: "20px",
  [theme.breakpoints.down("md")]: {
    display: "flex",
  },
}));

function LandingHeader({ onClick }) {
  const [navbar, setNavbar] = useState(false);

  const toggle = () => {
    setNavbar((prev) => !prev);
  };

  return (
    <RootStyle sx={{ background: "rgba(255, 255, 255, 0.1)" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          height: "53px",
          pl: 1,
        }}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <img height="32px" width="29px" src={LoopLogo} alt="logo" />
          <Typography sx={{ color: "white" }}>Talk It Out</Typography>
        </Stack>

        <Button
          onClick={toggle}
          sx={{ color: "black", display: { xs: "block", md: "none" } }}
        >
          {navbar ? (
            <CloseIcon sx={{ color: "white" }} />
          ) : (
            <MenuIcon sx={{ color: "white" }} />
          )}
        </Button>

        <Box
          sx={{
            width: "60%",
            display: { xs: "none", md: "flex" },
            justifyContent: "flex-end",
            alignItems: "center",
            pr: 2,
          }}
        >
          <ButtonPricing
            sx={{ ml: 1, mr: 1 }}
            onClick={() => onClick("pricing")}
          >
            Pricing
          </ButtonPricing>
          <ButtonFAQs sx={{ ml: 1, mr: 1 }} onClick={() => onClick("faqs")}>
            FAQs
          </ButtonFAQs>
          <Box
            sx={{
              width: "1px",
              bgcolor: "white",
              height: "30px",
              mr: 1,
            }}
          />
          <ButtonLogin onClick={() => onClick("loginModal")}>Login</ButtonLogin>
        </Box>
      </Box>
      {navbar && (
        <MenuBox>
          <ButtonPricing
            sx={{ mt: 1, mb: 1 }}
            fullWidth
            variant="contained"
            onClick={() => onClick("pricing")}
          >
            Pricing
          </ButtonPricing>
          <ButtonFAQs
            fullWidth
            variant="contained "
            onClick={() => onClick("faqs")}
          >
            FAQs
          </ButtonFAQs>
          <ButtonLogin
            fullWidth
            variant="contained"
            onClick={() => onClick("loginModal")}
          >
            Login
          </ButtonLogin>
        </MenuBox>
      )}
    </RootStyle>
  );
}

export default LandingHeader;
