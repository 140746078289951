import React, { useEffect, useState } from "react";
import { LinearProgress } from "@mui/material";
import { Button, Divider, Box, Stack } from "@mui/material";
import { TextField, styled, Typography } from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";
import SendIcon from "@mui/icons-material/Send";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useLocation, useParams } from "react-router-dom";
import Toast from "../../components/Toast";
import { FormattedDate } from "../../utils/FormattedDate";
import ReactGA from "../../Analytics";
import LoopLogo from "../../assets/images/Logo.png";

const TextBox = styled(Box)({
  display: "inline-block",
  background: "rgba(255, 255, 255, 0.12)",
  padding: "8px",
  margin: "4px",
  borderRadius: "4px",
  maxWidth: "60%",
});

function EmbedChat({ _id }) {
  const { id } = useParams();
  const location = useLocation();
  const [query, setQuery] = useState("");
  const [conversation, setConversation] = useState([]);
  const [question, setQuestion] = useState("");
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(1);
  const [show, setShow] = useState(false);
  const [botId] = useState(_id ? _id : id);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: "Embed Chat",
    });
  }, []);

  const handleSubmit = async () => {
    setLoading(true);
    const timer = setInterval(() => {
      setProgress((prev) => (prev >= 100 ? 1 : prev + 1));
    }, 800);
    setQuestion(query);

    let newChat = [];
    newChat.push(query);

    let myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer V78L3fNtjQ7quJ94h8YVSg==1VYOuAp34mHUAbPp"
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `https://api.urhja.com/query_chatbot?query=${query}?&botId=${botId}&hId=`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        clearInterval(timer);
        let res = JSON.parse(result);
        if (res.response) {
          newChat.push(res.response);
          setConversation((prev) => [...prev, { history: newChat }]);
          setQuestion("");
          setQuery("");
          setLoading(false);
          setProgress(1);
        } else {
          setQuestion("");
          if (res.error == "ChatBot not found") {
            setProgress(1);
            clearInterval(timer);
            setConversation([]);
            setLoading(false);
            Toast("error", res.error);
          } else {
            newChat.push(res.error);
            setConversation((prev) => [...prev, { history: newChat }]);
            setLoading(false);
            setProgress(1);
            clearInterval(timer);
            Toast("error", res.error);
          }
        }
      })
      .catch((error) => {
        console.log("error:", error);
      });
  };

  const handleInput = (e) => {
    const { value } = e.target;
    setQuery(value);
  };
  const filtered = () => {
    return [...conversation];
  };
  const toggle = () => {
    setShow((prev) => !prev);
  };

  return (
    <Box
      sx={{
        bottom: 10,
        right: 10,
        position: "fixed",
        zIndex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        justifyContent: "flex-end",
        gap: 1,
      }}
    >
      {show && (
        <Box
          sx={{
            background: "white",
            borderRadius: "6px",
            padding: "8px",
            minHeight: "400px",
            maxHeight: "900px",
            // width: "100%",
            width: { xs: "95%", md: "350px" },
            border: "1px solid grey",
          }}
        >
          <Stack
            height="36px"
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              sx={{
                fontFamily: "Raleway",
                fontWeight: 500,
                fontSize: "12px",
                color: "rgba(53, 53, 53, 0.5)",
              }}
            >
              Today - {FormattedDate()}
            </Typography>
            <CachedIcon
              sx={{
                color: "rgba(53, 53, 53, 0.5)",
              }}
            />
          </Stack>
          <Divider />
          <Box
            style={{
              minHeight: "300px",
              maxHeight: "500px",
              overflowX: "hidden",
            }}
          >
            <TextBox>
              <Typography
                sx={{
                  fontFamily: "Raleway",
                  fontWeight: 500,
                  fontSize: "12px",
                }}
              >
                How can i help you?
              </Typography>
            </TextBox>

            {filtered()?.map((item, i) => (
              <div key={i}>
                <Box sx={{ display: "flex", justifyContent: "end" }}>
                  <TextBox
                    sx={{
                      background: "#2677D9",
                      justifyContent: "end",
                      mt: "4px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Raleway",
                        fontWeight: 500,
                        fontSize: "12px",
                        color: "rgba(255, 255, 255, 1)",
                      }}
                    >
                      {item?.history[0]}
                    </Typography>
                  </TextBox>
                </Box>
                <TextBox>
                  <Typography
                    sx={{
                      fontFamily: "Raleway",
                      fontWeight: 500,
                      fontSize: "12px",
                    }}
                  >
                    {item?.history[1]}
                  </Typography>
                </TextBox>
              </div>
            ))}

            {question && (
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <TextBox
                  sx={{
                    background: "#2677D9",
                    display: "inline-block",
                    justifyContent: "end",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Raleway",
                      fontWeight: 500,
                      fontSize: "12px",
                      color: "rgba(255, 255, 255, 1)",
                    }}
                  >
                    {question}
                  </Typography>
                </TextBox>
              </Box>
            )}
          </Box>

          {loading ? (
            <Box sx={{ width: "100%", height: "40px" }}>
              <Typography sx={{ fontSize: "12px" }}>{`${Math.round(
                progress
              )}%`}</Typography>
              <Box
                sx={{
                  height: "6px",
                  background: "rgba(242, 242, 242, 0.2)",
                }}
              >
                <LinearProgress
                  sx={{ height: "6px" }}
                  variant="determinate"
                  value={progress}
                />
              </Box>
            </Box>
          ) : (
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              placeholder="Ask..."
              InputProps={{
                endAdornment: (
                  <SendIcon
                    onClick={handleSubmit}
                    sx={{
                      color: "rgba(53, 53, 53, 0.5)",
                      cursor: "pointer",
                      ml: 1,
                    }}
                  />
                ),
              }}
              sx={{
                border: "1px solid rgba(53, 53, 53, 0.5)",
                borderRadius: "4px",
                height: "40px",
                input: {
                  color: "#353535",
                  fontSize: "14px",
                  fontFamily: "Raleway",
                  fontWeight: 500,
                },
              }}
              name="query"
              value={query}
              onChange={handleInput}
              onKeyDown={(e) => {
                if (e.key == "Enter") {
                  handleSubmit();
                }
              }}
            />
          )}
        </Box>
      )}
      {_id ? (
        <Box>
          <Button
            variant="contained"
            sx={{
              background: "black !important",
              float: "right",
              height: "60px",
              textTransform: "none",
              borderRadius: "80px",
            }}
            onClick={toggle}
          >
            <img
              height="30px"
              width="40px"
              style={{ marginRight: "5px" }}
              src={LoopLogo}
              alt=""
            />
            {show ? (
              <ExpandMoreIcon sx={{ fontSize: "30px" }} />
            ) : (
              "Chat With Us"
            )}
          </Button>
        </Box>
      ) : (
        <Box>
          <Button
            variant="contained"
            sx={{
              float: "right",
              height: "60px",
              textTransform: "none",
              borderRadius: "80px",
            }}
            onClick={toggle}
          >
            {show ? <ExpandMoreIcon sx={{ fontSize: "30px" }} /> : "Chat"}
          </Button>
        </Box>
      )}
    </Box>
  );
}

export default EmbedChat;
