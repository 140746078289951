import React, { useEffect } from "react";
import { Box, Paper, Stack, Table } from "@mui/material";
import { TableBody, TableCell, TableContainer, TableHead } from "@mui/material";
import { TableRow, Typography, styled, tableCellClasses } from "@mui/material";
import payIcon from "../../assets/images/subs.png";
import { Verified } from "@mui/icons-material";
import GppBadIcon from "@mui/icons-material/GppBad";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import { useSelector } from "react-redux";
import moment from "moment";
import { FormattedDate } from "../../utils/FormattedDate";
import ReactGA from "../../Analytics";
import { useLocation } from "react-router-dom";

const HeadBox = styled(Box)(({ theme }) => ({
  margin: "20px 0",
  background: "#FFFFFF33",
  minHeight: "76px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "20px",
  gap: "10px",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#25A996",
    color: theme.palette.common.white,
    borderBottom: "1px solid #3535354D",
  },
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "1px solid #3535354D",
    fontSize: 14,
    backgroundColor: "#353535",
  },
}));
const BodyCell = styled(TableCell)({
  borderBottom: "1px solid #3535354D",
});

function HistorySection() {
  const { user } = useSelector((state) => state.user);

  const location = useLocation();
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: "Payment History",
    });
  }, []);

  return (
    <Box sx={{ minHeight: "50vh", width: "100%" }}>
      <HeadBox>
        <Stack alignItems="center" direction="row" spacing={2}>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <img height="40px" width="40px" src={payIcon} alt="" />
          </Box>
          <Stack>
            <Typography
              sx={{
                fontFamily: "Raleway",
                fontSize: "16px",
                fontWeight: 600,
                color: "#353535",
                textTransform: "capitalize",
              }}
            >
              {user?.subscription?.subType}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Raleway",
                fontSize: "14px",
                fontWeight: 400,
                color: "#353535",
              }}
            >
              Billing Monthly -- Next payment on{" "}
              {FormattedDate(user.nextPaymentDate)} for $
              {user?.subscription?.price}
              {/* Billing Monthly -- Next payment on June 14, 2023 for $9 */}
            </Typography>
          </Stack>
        </Stack>

        <Stack direction="row" spacing={5}>
          <Box sx={{ display: { xs: "block", sm: "none" } }}>
            <img height="40px" width="40px" src={payIcon} alt="" />
          </Box>
          <Box
            sx={{
              background: "#25A996",
              borderRadius: "36px",
              padding: "10px",
            }}
          >
            <Stack direction="row" spacing={1}>
              <Verified sx={{ color: "#FFFFFF" }} />
              <Typography
                sx={{
                  fontFamily: "Raleway",
                  fontSize: { xs: "12px", sm: "16px" },
                  fontWeight: 600,
                  color: "#FFFFFF",
                }}
              >
                Active
              </Typography>
            </Stack>
          </Box>
        </Stack>
      </HeadBox>
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell width="50px">#</StyledTableCell>
              <StyledTableCell>Amount</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Description</StyledTableCell>
              <StyledTableCell>Subscription Type</StyledTableCell>
              <StyledTableCell>Date</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {user?.payemntHistory?.map((item, i) => {
              return (
                <TableRow key={i} sx={{ background: "#4BA3FA" }}>
                  <BodyCell width="50px" component="th" scope="row">
                    {i + 1}
                  </BodyCell>
                  <BodyCell>${item.amount / 100}</BodyCell>
                  <BodyCell width="200px">
                    {item?.status == "succeeded" && (
                      <Box
                        sx={{
                          borderRadius: "4px",
                          background: "#13C39C",
                          p: "5px 10px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Raleway",
                            fontSize: "14px",
                            fontWeight: 700,
                            color: "#FFFFFF",
                          }}
                        >
                          <Verified sx={{ mr: 1, color: "#ffffff" }} />{" "}
                          Successful
                        </Typography>
                      </Box>
                    )}
                    {item?.stauts == "cancelled" && (
                      <Box
                        sx={{
                          borderRadius: "4px",
                          background: "#DE3837",
                          p: "5px 10px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Raleway",
                            fontSize: "14px",
                            fontWeight: 700,
                            color: "#FFFFFF",
                          }}
                        >
                          <GppBadIcon sx={{ mr: 1, color: "#ffffff" }} />{" "}
                          Cancelled
                        </Typography>
                      </Box>
                    )}
                    {item?.status == "error" && (
                      <Box
                        sx={{
                          borderRadius: "4px",
                          background: "#C39213",
                          p: "5px 10px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Raleway",
                            fontSize: "14px",
                            fontWeight: 700,
                            color: "#FFFFFF",
                          }}
                        >
                          <GppMaybeIcon sx={{ mr: 1, color: "#ffffff" }} />{" "}
                          Error
                        </Typography>
                      </Box>
                    )}
                  </BodyCell>
                  <BodyCell>{item?.type}</BodyCell>
                  <BodyCell>Monthly Plan</BodyCell>
                  <BodyCell>
                    {moment
                      .unix(item?.paymentDate)
                      .format("DD-MM-YYYY hh:mm A")}
                  </BodyCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default HistorySection;
