import React, { useState, useRef } from "react";
import Repo from "../../../repo/Repo";
import Toast from "../../../components/Toast";
import { useDispatch } from "react-redux";
import { getUser, userAdd, userLogged } from "../../Admin/userSlice";
import LoginLogo from "../../../assets/images/login.png";
import { Box, Button, Dialog, InputAdornment, IconButton } from "@mui/material";
import { DialogContent, Stack, TextField, Typography } from "@mui/material";
import TailSpinLoader from "../../../components/TailSpinLoader";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import gIcon from "../../../assets/images/G.png";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/;

function Login({ open, onClose }) {
  const dispatch = useDispatch();
  const emailRef = useRef(null);
  const passRef = useRef(null);
  const [showPassword, setShowPassword] = useState(false);
  const [state, setState] = useState({
    email: "",
    password: "",
    loading: false,
    showPass: false,
  });

  const toggle = () => setShowPassword((show) => !show);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleLogin = async () => {
    if (!state.email) {
      Toast("info", "Enter email");
      return;
    }
    if (!state.password) {
      Toast("info", "Enter password");
      return;
    }
    if (emailRegex.test(state.email)) {
      Toast("info", "Invalid Email");
      return;
    }
    setState((prev) => ({ ...prev, loading: true }));
    let { data } = await Repo.user({
      request: {
        method: "loginUser",
        data: {
          email: state.email,
          password: state.password,
        },
      },
    });
    if (data.response?.status?.statusCode == 200) {
      localStorage.setItem("loggedIn", true);
      localStorage.setItem("email", data.response.data.userObj?.email);
      dispatch(userAdd(data.response.data.userObj));
      dispatch(userLogged("user"));
      setState((prev) => ({ ...prev, loading: false }));
    } else {
      Toast("info", "invalid credientials");
      setState((prev) => ({ ...prev, loading: false }));
    }
  };
  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const userInfo = await axios
        .get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        })
        .then((res) => res.data);
      localStorage.setItem("loggedIn", true);
      localStorage.setItem("email", userInfo?.email);
      dispatch(getUser(true));
    },
    onError: () => {
      Toast("info", "Login Failed");
      console.log("Login Failed");
    },
  });

  const handleEnter = (name) => {
    if (name == "email") {
      if (!state.email) {
        Toast("info", "Enter email");
      } else if (state.email && !state.password) {
        passRef.current.focus();
      }
      if (state.email && state.password) {
        handleLogin();
      }
    } else {
      if (!state.password) {
        Toast("info", "Enter password");
      } else if (!state.email && state.password) {
        emailRef.current.focus();
      }
      if (state.email && state.password) {
        handleLogin();
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose("loginModal")}
      fullWidth
      maxWidth="xs"
    >
      <DialogContent>
        <Stack spacing={2} alignItems="center" justifyContent="center">
          <Stack alignItems="center">
            <img width="40px" src={LoginLogo} alt="" />
            <Typography
              variant="h6"
              sx={{ fontFamily: "Raleway", fontWeight: 700 }}
            >
              Login
            </Typography>
          </Stack>

          <TextField
            fullWidth
            inputRef={emailRef}
            size="small"
            name="email"
            variant="outlined"
            placeholder="email"
            value={state.email}
            onChange={handleInput}
            onKeyDown={(e) => {
              if (e.key == "Enter") {
                handleEnter("email");
              }
            }}
          />
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            inputRef={passRef}
            name="password"
            type={showPassword ? "text" : "password"}
            placeholder="password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={toggle} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={state.password}
            onChange={handleInput}
            onKeyDown={(e) => {
              if (e.key == "Enter") {
                handleEnter("password");
              }
            }}
          />
          <Stack width="100%" alignItems="center" spacing={1}>
            {state.loading ? (
              <TailSpinLoader />
            ) : (
              <Stack direction="row" justifyContent="center" spacing={2}>
                <Button
                  size="small"
                  variant="outlined"
                  sx={{ textTransform: "none" }}
                  onClick={() => onClose("loginModal")}
                >
                  Cancel
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  sx={{ textTransform: "none" }}
                  onClick={handleLogin}
                >
                  Access
                </Button>
              </Stack>
            )}
            <Typography
              variant="body"
              sx={{ color: "#1E88E5", cursor: "pointer" }}
              onClick={() => onClose("forgotModal")}
            >
              Forgot Password?
            </Typography>
            <Stack direction="row" spacing={1}>
              <Typography onClick={() => onClose("forgotModal")}>
                No account?
              </Typography>
              <Typography
                sx={{ color: "rgb(34 197 94)", cursor: "pointer" }}
                onClick={() => onClose("signupModal")}
              >
                Sign Up
              </Typography>
            </Stack>
          </Stack>

          <Button
            variant="contained"
            sx={{
              p: "1px 10px 1px 5px",
              textTransform: "none",
              fontFamily: "Raleway",
              fontWeight: 600,
            }}
            startIcon={
              <Box
                sx={{
                  background: "white",
                  borderRadius: "4px",
                }}
              >
                <img src={gIcon} alt="" />
              </Box>
            }
            onClick={login}
          >
            Sign in with Google
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default Login;
