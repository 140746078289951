import React, { useState } from "react";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import Hobby from "../subscription/cards/Hobby";
import Growth from "../subscription/cards/Growth";
import Standard from "../subscription/cards/Standard";
import Free from "../subscription/cards/Free";
import Unlimited from "../subscription/cards/Unlimited";
import Login from "../authentication/login";
import { useSelector } from "react-redux";
import Payment from "../subscription/Payment";
import Contact from "../subscription/Contact";

function SubscribeSection() {
  const { isLoggedIn } = useSelector((state) => state.user);
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({
    open: false,
    amount: 0,
    contact: false,
    plan: "",
  });

  const toggle = (name) => {
    if (isLoggedIn) {
      if (name == "growth") {
        setState((prev) => ({
          ...prev,
          amount: 19,
          plan: name,
          open: !prev.open,
        }));
      } else if (name == "standard") {
        setState((prev) => ({
          ...prev,
          amount: 49,
          plan: name,
          open: !prev.open,
        }));
      } else if (name == "hobby") {
        setState((prev) => ({
          ...prev,
          amount: 9,
          plan: name,
          open: !prev.open,
        }));
      } else if (name == "unlimited") {
        setState((prev) => ({
          ...prev,
          amount: 0,
          plan: "unlimited",
          contact: !prev.contact,
        }));
      } else {
        setState((prev) => ({
          ...prev,
          amount: 0,
          plan: "",
          contact: false,
          open: false,
        }));
      }
    } else {
      setOpen((prev) => !prev);
    }
  };

  return (
    <Box
      sx={{
        background: "linear-gradient(90deg, #17CEAD 0%,  #186ED0 100%)",
        padding: "2%",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        pb: 10,
      }}
    >
      {/* <Stack
        sx={{
          position: { md: "relative" },
          display: { md: "block", xs: "none" },
        }}
      >
        <img
          src={Pattern}
          alt=""
          style={{
            mixBlendMode: "soft-light",
          }}
        />
      </Stack> */}
      <Container>
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ width: "100%" }}
          spacing={1}
        >
          <Typography
            sx={{
              color: "white",
              fontSize: { md: "40px", xs: "24px" },
              fontWeight: 900,
            }}
          >
            Pricing
            <span style={{ marginLeft: "10px", color: "#353535" }}>Plans</span>
          </Typography>

          <Grid
            sx={{ alignItems: "center", justifyContent: "center" }}
            container
            spacing={0.5}
          >
            <Grid item lg={2.4} md={4} sm={6} xs={12}>
              <Free />
            </Grid>
            <Grid item lg={2.4} md={4} sm={6} xs={12}>
              <Hobby onClick={() => toggle("hobby")} />
            </Grid>
            <Grid item lg={2.4} md={4} sm={6} xs={12}>
              <Growth onClick={() => toggle("growth")} />
            </Grid>
            <Grid item lg={2.4} md={4} sm={6} xs={12}>
              <Standard onClick={() => toggle("standard")} />
            </Grid>
            <Grid item lg={2.4} md={4} sm={6} xs={12}>
              <Unlimited onClick={() => toggle("unlimited")} />
            </Grid>
          </Grid>
        </Stack>
      </Container>
      {open && <Login open={open} onClose={toggle} />}
      {state.open && (
        <Payment open={state.open} plan={state.plan} onClose={toggle} />
      )}
      {state.contact && <Contact open={state.contact} onClose={toggle} />}
    </Box>
  );
}

export default SubscribeSection;
