import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import freeIcon from "../../../assets/images/free icon.svg";
import DoneIcon from "@mui/icons-material/Done";
import { IconBox, RootStyle, SubTitle } from "./styles";
import { useSelector } from "react-redux";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import { BtnSubscribed } from "../style";

function Hobby({ onClick }) {
  const { user } = useSelector((state) => state.user);

  return (
    <RootStyle>
      <Stack>
        <Stack
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
        >
          <IconBox>
            <img width="22px" height="22px" src={freeIcon} alt="" />
          </IconBox>
          <Stack>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: 500,
                fontSize: "12px",
                color: "#C8C8C8",
                textAlign: "center",
              }}
            >
              PLAN TYPE
            </Typography>
            <Typography
              sx={{
                fontFamily: "Raleway",
                fontWeight: 700,
                fontSize: { xs: "16px", md: "20px" },
                color: "#353535",
                textAlign: "center",
              }}
            >
              Free Trial
            </Typography>
          </Stack>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontWeight: 600,
              fontSize: { xs: "16px", md: "20px" },
              color: "#555555",
            }}
          >
            $0
          </Typography>
        </Stack>

        <Stack alignItems="center" justifyContent="center">
          <Typography
            sx={{
              fontFamily: "Urbanist",
              fontWeight: 600,
              fontSize: "14px",
              color: "#555555",
            }}
          >
            Billed Monthly
          </Typography>
        </Stack>

        <Stack mt={1}>
          <Stack direction="row" spacing={1}>
            <DoneIcon sx={{ color: "#555555" }} />
            <SubTitle sx={{ color: "#828282" }}>
              15
              <span style={{ marginLeft: "5px", fontWeight: 500 }}>
                total messages.
              </span>
            </SubTitle>
          </Stack>
          <Stack direction="row" spacing={1}>
            <DoneIcon sx={{ color: "#555555" }} />
            <SubTitle sx={{ color: "#828282" }}>
              Single
              <span style={{ marginLeft: "5px", fontWeight: 500 }}>
                chatbot.
              </span>
            </SubTitle>
          </Stack>
          <Stack direction="row" spacing={1}>
            <DoneIcon sx={{ color: "#555555" }} />
            <SubTitle sx={{ color: "#828282" }}>
              500,000
              <span style={{ marginLeft: "5px", fontWeight: 500 }}>
                characters limit.
              </span>
            </SubTitle>
          </Stack>
          <Stack direction="row" spacing={1}>
            <DoneIcon sx={{ color: "#555555" }} />
            <SubTitle sx={{ color: "#828282" }}>
              Embed
              <span style={{ marginLeft: "5px", fontWeight: 500 }}>
                on unlimited websites.
              </span>
            </SubTitle>
          </Stack>
          <Stack direction="row" spacing={1}>
            <DoneIcon sx={{ color: "#555555" }} />
            <SubTitle sx={{ color: "#828282" }}>
              Upload
              <span style={{ marginLeft: "5px", fontWeight: 500 }}>
                one file.
              </span>
            </SubTitle>
          </Stack>
          <Stack direction="row" spacing={1}>
            <DoneIcon sx={{ color: "#555555" }} />
            <SubTitle sx={{ color: "#828282" }}>
              Share
              <span style={{ marginLeft: "5px", fontWeight: 500 }}>
                chatbot with everyone through a simple link.
              </span>
            </SubTitle>
          </Stack>
        </Stack>
      </Stack>
      <Stack justifyContent="center" alignItems="center">
        {user?.subscription?.subType === "free" ? (
          <BtnSubscribed variant="contained" fullWidth>
            Subscribed
          </BtnSubscribed>
        ) : (
          <Button
            fullWidth
            variant="contained"
            sx={{
              background: "#E8F3FF !important",
              border: "1px solid #555555",
              borderRadius: "6px",
              textTransform: "none",
              color: "#555555",
            }}
          >
            Free
          </Button>
        )}
      </Stack>
    </RootStyle>
  );
}

export default Hobby;
