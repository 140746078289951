import React from "react";
import { Box, Grid, Stack, Typography, styled } from "@mui/material";
import AnalysisVideo from "../../assets/videos/analysis video2.mp4";
import demoVideo from "../../assets/videos/Talkitout.mp4";
import Pattern from "../../assets/images/Pattern.svg";
import chatImg from "../../assets/images/chat image.svg";

const VideoBox = styled(Grid)({
  padding: "25px",
  borderRadius: "8px",
});
export const RootStyle = styled(Box)({
  background: "linear-gradient(90deg, #17CEAD 0%,  #186ED0 100%)",
  padding: "2% 5%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100vh",
});
function AnalysisSection() {
  return (
    <RootStyle>
      {/* <Stack
        width="100%"
        sx={{
          position: { md: "relative" },
          display: { md: "block", xs: "none" },
        }}
      >
        <img
          width="100%"
          src={Pattern}
          alt=""
          style={{
            mixBlendMode: "soft-light",
          }}
        />
      </Stack>
      <Box
        sx={{
          position: "absolute",
          background: "rgba(255, 255, 255, 0.1)",
          boxShadow: "0px 0px 24px rgba(0, 0, 0, 0.08)",
          backdropFilter: "blur(12px)",
          borderRadius: "12px ",
          width: { xs: "95%", md: "82%" },
        }}
      >
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Box
              sx={{
                padding: { xs: "10px", md: "83px 33px" },
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Raleway",
                  fontWeight: 400,
                  fontSize: { xs: "15px", md: "20px" },
                  color: "#353535",
                  textShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
                }}
              >
                Create a chatbot for you by crawling the website and training
                the AI on its content. I can then embed the chatbot as widget or
                iFrame on the website!
              </Typography>
            </Box>
          </Grid>
          <Grid item md={6} xs={12}>
            <Stack
              padding={{ xs: "10px", md: "83px 33px" }}
              alignItems="center"
              display="inline-block"
            >
              <img src={chatImg} alt="" />
            </Stack>
          </Grid>
        </Grid>
      </Box> */}
      <Stack alignItems="center" justifyContent="center">
        <Stack sx={{ position: "relative" }}>
          <img
            src={Pattern}
            alt=""
            style={{
              mixBlendMode: "soft-light",
            }}
          />
        </Stack>
        <Grid
          container
          sx={{ position: "absolute" }}
          alignItems="center"
          justifyContent="center"
        >
          <VideoBox item md={8} xs={12} className="md:backdrop-blur-2xl">
            <video
              src={demoVideo}
              type="video/mp4"
              autoPlay
              loop
              muted
              style={{ borderRadius: "8px" }}
            >
              Your browser does not support the video.
            </video>
          </VideoBox>
        </Grid>
      </Stack>
    </RootStyle>
  );
}

export default AnalysisSection;
