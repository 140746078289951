import React from "react";
import { TextField, Button, InputAdornment } from "@mui/material";

function MyTextField(props) {
  const { value, onChange, title, onClick, placeholder } = props;
  return (
    <TextField
      fullWidth
      size="small"
      placeholder={placeholder}
      sx={{
        background: "rgba(255, 255, 255, 0.05)",
        borderRadius: "6px",
        height: "40px",
        color: "rgba(53, 53, 53, 0.5)",
        border: "none",
        "& fieldset": { border: "none" },
      }}
      onKeyDown={(e) => {
        if (e.key == "Enter") {
          onClick();
        }
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment>
            <Button
              variant="contained"
              sx={{
                background: "rgba(53, 53, 53, 1) !important",
                color: "#FFFFFF",
                borderRadius: "6px",
                textTransform: "none",
                fontSize: { xs: "10px", md: "14px" },
              }}
              onClick={onClick}
            >
              {title}
            </Button>
          </InputAdornment>
        ),
      }}
      value={value}
      onChange={onChange}
    />
  );
}

export default MyTextField;
