import React, { useState } from "react";
import { TextField, Button } from "@mui/material";
import { Dialog, DialogContent, Stack, Typography } from "@mui/material";
import Repo from "../../repo/Repo";
import TailSpinLoader from "../../components/TailSpinLoader";
import Toast from "../../components/Toast";

function Contact({ open, onClose }) {
  const [state, setState] = useState({
    loading: false,
    email: "",
    message: "",
  });
  const handleInput = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };
  const toggle = () => {
    if (!state.loading) {
      onClose();
    }
  };
  const handleSubmit = async () => {
    if (!state.email || !state.message) {
      Toast("info", "Kindly enter Email and Message");
      return;
    }
    setState((prev) => ({ ...prev, loading: true }));
    let { data } = await Repo.email({
      request: {
        method: "sendMessage",
        data: {
          to: "Ali@veracious.ai",
          message: state.message,
          from: state.email,
        },
      },
    });
    setState((prev) => ({ ...prev, loading: false }));
    if (data.response.data.success === true) {
      Toast("success", "Email Submitted Successfully");
      toggle();
    } else {
      Toast("error", "Email Not Submitted ");
    }
  };
  return (
    <Dialog open={open} onClose={toggle} fullWidth maxWidth="sm">
      <DialogContent>
        <Stack spacing={3}>
          <Typography variant="h4" sx={{ textAlign: "center" }}>
            Contact Us
          </Typography>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            name="email"
            label="Email"
            type="email"
            value={state.email}
            onChange={handleInput}
          />
          <TextField
            fullWidth
            multiline
            variant="outlined"
            minRows={4}
            maxRows={7}
            name="message"
            label="message"
            type="text"
            value={state.message}
            onChange={handleInput}
          />
          {state.loading && <TailSpinLoader />}
          {!state.loading && (
            <Stack
              direction={{ xs: "column", sm: "row" }}
              justifyContent="space-between"
              spacing={{ xs: 1 }}
            >
              <Button
                variant="outlined"
                sx={{ textTransform: "none" }}
                onClick={toggle}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleSubmit}
                sx={{
                  background: "rgba(33, 181, 190, 1) !important",
                  textTransform: "none",
                }}
              >
                Submit
              </Button>
            </Stack>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default Contact;
