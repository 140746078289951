import React, { useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoneIcon from "@mui/icons-material/Done";
const CopyButton = ({ text }) => {
  const [copy, setCopy] = useState(false);
  const handleCopy = () => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopy(true);
        console.log("Text copied to clipboard");
      })
      .catch((error) => {
        console.error("Failed to copy text: ", error);
      });
  };

  return (
    <button
      onClick={handleCopy}
      style={{
        background: "rgba(255, 255, 255, 0.1)",
        borderRadius: "6px",
        padding: "5px",
        width: "36px",
        height: "36px",
      }}>
      {copy ? <DoneIcon className="text-green-900" /> : <ContentCopyIcon />}
    </button>
  );
};

export default CopyButton;
