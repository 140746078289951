import React, { useEffect, useRef, useState } from "react";
import { Box, Stack, styled, Rating, Avatar } from "@mui/material";
import { Typography, Button, useTheme, useMediaQuery } from "@mui/material";
import QuotesIcon from "../../assets/images/quotes icon.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import User1 from "../../assets/images/user 1.jpg";
import User2 from "../../assets/images/user 2.jpg";
import User3 from "../../assets/images/user 3.jpg";
import User4 from "../../assets/images/user 4.jpg";

const RootStyle = styled(Box)({
  background: "linear-gradient(90deg, #186ED0 0%, #17CEAD 100%)",
  padding: "5%",
});

const SlideCard = styled(Box)({
  background: "#FFFFFF33",
  maxWidth: "350px",
  height: "435px",
  borderRadius: "8px",
  boxShadow: "0px 0px 24px 0px #00000014",
});
const NameTitle = styled(Typography)({
  fontFamily: "Raleway",
  fontSize: "20px",
  fontWeight: 700,
  color: "#353535",
});

function Testimonials() {
  const swiperRef = useRef();
  const [activeStep, setActiveStep] = useState(0);
  const [stepsLength, setStepsLength] = useState(1);
  const [slides, setSlides] = useState(3.4);

  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.only("xs"));
  const isSmScreen = useMediaQuery(theme.breakpoints.only("sm"));
  const isMdScreen = useMediaQuery(theme.breakpoints.only("md"));
  const isLgScreen = useMediaQuery(theme.breakpoints.only("lg"));
  const isXlScreen = useMediaQuery(theme.breakpoints.only("xl"));

  useEffect(() => {
    const handleScreenSizeChange = () => {
      if (isXsScreen) {
        setSlides(1.2);
        setStepsLength(3);
      } else if (isSmScreen) {
        setSlides(1.6);
        setStepsLength(2);
      } else if (isMdScreen) {
        setStepsLength(4);
        setSlides(2.2);
        setStepsLength(1);
      } else if (isLgScreen) {
        setSlides(3.4);
        setStepsLength(1);
      } else if (isXlScreen) {
        setStepsLength(1);
        setSlides(3.4);
      }
    };
    handleScreenSizeChange();
    window.addEventListener("resize", handleScreenSizeChange);
    return () => {
      window.removeEventListener("resize", handleScreenSizeChange);
    };
  }, [isXsScreen, isSmScreen, isMdScreen, isLgScreen, isXlScreen]);

  const handleNext = () => {
    if (activeStep < stepsLength) {
      setActiveStep((prev) => prev + 1);
      swiperRef.current.slideNext();
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prev) => prev - 1);
      swiperRef.current.slidePrev();
    }
  };

  return (
    <RootStyle>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            color: "white",
            fontSize: { md: "40px", xs: "24px" },
            fontWeight: 900,
            mb: 3,
          }}
        >
          What People Say About
          <span style={{ marginLeft: "10px", color: "#353535" }}>US</span>
        </Typography>
      </Box>

      <Swiper
        spaceBetween={20}
        slidesPerView={slides}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
        className="mySwiper"
      >
        <SwiperSlide>
          <SlideCard>
            <Stack p="5%" justifyContent="space-between" height="100%">
              <Stack spacing={1}>
                <img width="27px" height="22px" src={QuotesIcon} alt="" />
                <Typography
                  sx={{
                    fontFamily: "Raleway",
                    fontSize: "20px",
                    fontWeight: 400,
                  }}
                >
                  “I love the website embedding feature. Really helped for my
                  blog site”
                </Typography>
              </Stack>
              <Stack alignItems="center" justifyContent="center">
                <Rating name="ratings" value={5} readOnly />
                <Avatar
                  sx={{ height: "80px", width: "80px" }}
                  src={User1}
                  alt=""
                />
                <NameTitle>Mark Josh</NameTitle>
                <Typography
                  sx={{
                    fontFamily: "Raleway",
                    fontSize: "16px",
                    fontWeight: 500,
                    color: "#353535",
                  }}
                >
                  Designer
                </Typography>
              </Stack>
            </Stack>
          </SlideCard>
        </SwiperSlide>
        <SwiperSlide>
          <SlideCard>
            <Stack p="5%" justifyContent="space-between" height="100%">
              <Stack spacing={1}>
                <img width="27px" height="22px" src={QuotesIcon} alt="" />
                <Typography
                  sx={{
                    fontFamily: "Raleway",
                    fontSize: "20px",
                    fontWeight: 400,
                  }}
                >
                  “The response quality is really cool. It has actually
                  increased traffic on my website”
                </Typography>
              </Stack>
              <Stack alignItems="center" justifyContent="center">
                <Rating name="ratings" value={5} readOnly />
                <Avatar
                  sx={{ height: "80px", width: "80px" }}
                  src={User2}
                  alt=""
                />
                <NameTitle>Stephan Harry</NameTitle>
                <Typography
                  sx={{
                    fontFamily: "Raleway",
                    fontSize: "16px",
                    fontWeight: 500,
                    color: "#353535",
                  }}
                >
                  PM at BrillHooks
                </Typography>
              </Stack>
            </Stack>
          </SlideCard>
        </SwiperSlide>
        <SwiperSlide>
          <SlideCard>
            <Stack p="5%" justifyContent="space-between" height="100%">
              <Stack spacing={1}>
                <img width="27px" height="22px" src={QuotesIcon} alt="" />
                <Typography
                  sx={{
                    fontFamily: "Raleway",
                    fontSize: "20px",
                    fontWeight: 400,
                  }}
                >
                  “Great value for such a mouth watering subscription. Will
                  recommend you all”
                </Typography>
              </Stack>
              <Stack alignItems="center" justifyContent="center">
                <Rating name="ratings" value={5} readOnly />
                <Avatar
                  sx={{ height: "80px", width: "80px" }}
                  src={User3}
                  alt=""
                />
                <NameTitle>Angelina Stane</NameTitle>
                <Typography
                  sx={{
                    fontFamily: "Raleway",
                    fontSize: "16px",
                    fontWeight: 500,
                    color: "#353535",
                  }}
                >
                  Beautician
                </Typography>
              </Stack>
            </Stack>
          </SlideCard>
        </SwiperSlide>
        <SwiperSlide>
          <SlideCard>
            <Stack p="5%" justifyContent="space-between" height="100%">
              <Stack spacing={1}>
                <img width="27px" height="22px" src={QuotesIcon} alt="" />
                <Typography
                  sx={{
                    fontFamily: "Raleway",
                    fontSize: "20px",
                    fontWeight: 400,
                  }}
                >
                  “I like the share chat bot feature. Quickly sending details to
                  my clients”
                </Typography>
              </Stack>
              <Stack alignItems="center" justifyContent="center">
                <Rating name="ratings" value={5} readOnly />
                <Avatar
                  sx={{ height: "80px", width: "80px" }}
                  src={User4}
                  alt=""
                />
                <NameTitle>Henry</NameTitle>
                <Typography
                  sx={{
                    fontFamily: "Raleway",
                    fontSize: "16px",
                    fontWeight: 500,
                    color: "#353535",
                  }}
                >
                  Editor GoViral
                </Typography>
              </Stack>
            </Stack>
          </SlideCard>
        </SwiperSlide>
      </Swiper>

      <Stack my={2} direction="row" spacing={5}>
        <Button
          variant="contained"
          sx={{ background: "#FFFFFF33 !important", textTransform: "none" }}
          disabled={activeStep === 0}
          onClick={handleBack}
        >
          <ArrowBackIcon sx={{ color: "#353535" }} />
        </Button>
        <Button
          variant="contained"
          sx={{ background: "#FFFFFF33 !important", textTransform: "none" }}
          disabled={activeStep === stepsLength}
          onClick={handleNext}
        >
          <ArrowForwardIcon sx={{ color: "#353535" }} />
        </Button>
      </Stack>
    </RootStyle>
  );
}

export default Testimonials;
