import React, { useState } from "react";
import AdminHeader from "../../layout/user header";
import Payment from "./Payment";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getUser } from "../Admin/userSlice";
import Footer from "../home/Footer";
import Contact from "./Contact";
import { Box, styled, Grid } from "@mui/material";
import ReactGA from "../../Analytics";
import { useLocation } from "react-router-dom";
import Growth from "./cards/Growth";
import Hobby from "./cards/Hobby";
import Standard from "./cards/Standard";
import Unlimited from "./cards/Unlimited";
import Free from "./cards/Free";
import Feedback from "../home/Feedback";

const RootStyle = styled(Box)({
  background: "linear-gradient(90deg, #186ED0 0%, #17CEAD 100%)",
  padding: "2% 5%",
});
const CardsBox = styled(Box)({
  width: "100%",
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(230px, 1fr))",
  gap: 5,
  margin: "30px 0",
});

function Subscription() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { user } = useSelector((state) => state.user);
  const [state, setState] = useState({
    open: false,
    amount: 0,
    contact: false,
    plan: "",
  });

  useEffect(() => {
    if (user === "") {
      dispatch(getUser());
    }
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: "Subscription",
    });
  }, []);

  const toggle = (name) => {
    if (name == "growth") {
      setState((prev) => ({
        ...prev,
        amount: 19,
        plan: name,
        open: !prev.open,
      }));
    } else if (name == "standard") {
      setState((prev) => ({
        ...prev,
        amount: 49,
        plan: name,
        open: !prev.open,
      }));
    } else if (name == "hobby") {
      setState((prev) => ({
        ...prev,
        amount: 9,
        plan: name,
        open: !prev.open,
      }));
    } else if (name == "unlimited") {
      setState((prev) => ({
        ...prev,
        amount: 0,
        plan: "unlimited",
        contact: !prev.contact,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        amount: 0,
        plan: "",
        contact: false,
        open: false,
      }));
    }
  };

  return (
    <RootStyle>
      <AdminHeader />
      <Grid
        sx={{ my: 1, alignItems: "center", justifyContent: "center" }}
        container
        spacing={0.5}
      >
        <Grid item lg={2.4} md={4} sm={6} xs={12}>
          <Free onClick={() => toggle("hobby")} />
        </Grid>
        <Grid item lg={2.4} md={4} sm={6} xs={12}>
          <Hobby onClick={() => toggle("hobby")} />
        </Grid>
        <Grid item lg={2.4} md={4} sm={6} xs={12}>
          <Growth onClick={() => toggle("growth")} />
        </Grid>
        <Grid item lg={2.4} md={4} sm={6} xs={12}>
          <Standard onClick={() => toggle("standard")} />
        </Grid>
        <Grid item lg={2.4} md={4} sm={6} xs={12}>
          <Unlimited onClick={() => toggle("unlimited")} />
        </Grid>
      </Grid>

      <Footer />
      <Feedback />
      {state.open && (
        <Payment open={state.open} plan={state.plan} onClose={toggle} />
      )}
      {state.contact && <Contact open={state.contact} onClose={toggle} />}
    </RootStyle>
  );
}

export default Subscription;
