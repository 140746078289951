import React from "react";
import Modal from "../../utils/Modal";
import { useState } from "react";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TailSpin } from "react-loader-spinner";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import Repo from "../../repo/Repo";

function UpdatePassword({ open, onClose }) {
  const navigate = useNavigate();

  const location = useLocation();
  let email = "";
  let found = localStorage.getItem("email");
  if (found) {
    email = found;
  } else {
    const searchParams = new URLSearchParams(location.search);
    email = searchParams.get("email");
  }

  const [state, setState] = useState({
    loading: false,
    password: "",
    confirmPassword: "",
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const toggle = () => {
    if (!state.loading) {
      onClose();
    }
  };

  const handleReset = async () => {
    try {
      if (state.password && state.confirmPassword) {
        if (state.password === state.confirmPassword) {
          setState((prev) => ({ ...prev, loading: true }));

          let { data } = await Repo.user({
            request: {
              method: "updateUser",
              data: {
                _id: email,
                password: state.password,
                emailVerified: true,
              },
            },
          });
          let id = data.response.data.userId;
          let statusCode = data.response.status.statusCode;
          if (id && statusCode == 200) {
            localStorage.setItem("passwordChange", true);
            localStorage.setItem("password", state.password);
          }
          setState((prev) => ({ ...prev, loading: false }));
          onClose();
          if (location.pathname == "/reset-password") {
            navigate("/", { state: undefined });
          }
        } else {
          toast("Password and Confirm Password does not match");
        }
      } else {
        toast("Kindly fill both the fields");
      }
    } catch (err) {
      toast("Error");
      onClose();
    }
  };

  return (
    <Modal
      isOpen={open}
      onClose={toggle}
      title=""
      lg
      titleCenter
      content={
        <div className="flex flex-col justify-center items-center relative">
          <span className="font-medium text-gray-900 text-lg mb-6">
            Update Password
          </span>

          <div className="bg-gray-300 rounded flex w-full mb-2">
            <div className="input-bg rounded py-3 px-5">
              <FontAwesomeIcon icon={faLock} className="text-white" />
            </div>
            <input
              type="password"
              placeholder="new password"
              value={state.password}
              name="password"
              onChange={handleInput}
              className="outline-none bg-transparent px-4 w-full"
            />
          </div>
          <div className="bg-gray-300 rounded flex w-full mb-2">
            <div className="input-bg rounded py-3 px-5">
              <FontAwesomeIcon icon={faLock} className="text-white" />
            </div>
            <input
              type="password"
              placeholder="confirm password"
              value={state.confirmPassword}
              name="confirmPassword"
              onChange={handleInput}
              className="outline-none bg-transparent px-4 w-full"
            />
          </div>

          <div className="w-full">
            {state.loading ? (
              <div className="flex justify-center ">
                <TailSpin
                  height="50"
                  width="50"
                  color="#4fa94d"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            ) : (
              <div className="flex justify-between ">
                <button
                  className="rounded bg-sky-100 text-gray-500 px-5 py-3 font-medium mr-3"
                  onClick={toggle}
                >
                  Cancel
                </button>
                <button
                  className="rounded bg-sky-300 text-blue-600 px-5 py-3 font-medium"
                  onClick={handleReset}
                >
                  Update
                </button>
              </div>
            )}
          </div>
        </div>
      }
    />
  );
}

export default UpdatePassword;
