import React from "react";
import { Stack } from "@mui/material";
import { TailSpin } from "react-loader-spinner";

function TailSpinLoader() {
  return (
    <Stack direction="row" width="100%" justifyContent="center">
      <TailSpin
        height="50"
        width="50"
        color="#4fa94d"
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </Stack>
  );
}

export default TailSpinLoader;
