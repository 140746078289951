import React from "react";
import { Grid, Stack, Typography, styled } from "@mui/material";
import { RootStyle } from "./HomeSection";
import Stars from "../../assets/images/stars.png";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
export const GridBox = styled(Grid)({
  background: "rgba(255, 255, 255, 0.1)",
  borderRadius: "8px",
  padding: "20px",
  height: "200px",
  marginTop: "10px",
});
export const Foot = styled(Grid)({
  background: "rgba(255, 255, 255, 0.1)",
  borderRadius: "8px",
  padding: "20px",
});
function ReviewSection() {
  return (
    <RootStyle>
      <Stack
        height={{ md: "100vh" }}
        alignItems="center"
        justifyContent="space-evenly"
      >
        <Typography
          variant="h3"
          sx={{
            color: "white",
            fontSize: { md: "40px", xs: "24px" },
            fontWeight: 900,
          }}
        >
          How {"  "}
          <span style={{ color: "#353535" }}>It </span> Works
        </Typography>
        <Grid container justifyContent="space-around">
          <GridBox
            display="flex"
            flexDirection="column"
            item
            md={3}
            xs={12}
            justifyContent="space-between"
          >
            <Typography>Step1</Typography>
            <Typography variant="h5" sx={{ color: "#353535", fontWeight: 600 }}>
              Website URLs
            </Typography>
            <Stack flexDirection="row" justifyContent="space-between">
              <img src={Stars} alt="" />
              <ArrowForwardIosIcon
                fontSize="small"
                sx={{ color: "rgba(0, 0, 0, 0.6)" }}
              />
            </Stack>
            <Typography sx={{ color: "#353535" }}>
              Simply copy and paste your website page URLs
            </Typography>
          </GridBox>
          <GridBox
            display="flex"
            flexDirection="column"
            item
            md={3}
            xs={12}
            justifyContent="space-between"
          >
            <Typography>Step2</Typography>
            <Typography variant="h5" sx={{ color: "#353535", fontWeight: 600 }}>
              Training
            </Typography>
            <Stack flexDirection="row" justifyContent="space-between">
              <img src={Stars} alt="" />
              <ArrowForwardIosIcon
                fontSize="small"
                sx={{ color: "rgba(0, 0, 0, 0.6)" }}
              />
            </Stack>
            <Typography sx={{ color: "#353535" }}>
              Click ‘Create Chatbot’ and let the AI train itself on your
              included data.
            </Typography>
          </GridBox>
          <GridBox
            display="flex"
            flexDirection="column"
            item
            md={3}
            xs={12}
            justifyContent="space-between"
          >
            <Typography>Step3</Typography>
            <Typography variant="h5" sx={{ color: "#353535", fontWeight: 600 }}>
              Deploy
            </Typography>
            <Stack flexDirection="row" justifyContent="space-between">
              <img src={Stars} alt="" />
              <ArrowForwardIosIcon
                fontSize="small"
                sx={{ color: "rgba(0, 0, 0, 0.6)" }}
              />
            </Stack>
            <Typography sx={{ color: "#353535" }}>
              Finally use trained chatbot as widget on your website.
            </Typography>
          </GridBox>
        </Grid>
        <Grid container justifyContent="space-around">
          <Foot item md={12} sx={{ marginTop: { xs: 1.5, md: 0 } }}>
            You can also upload your documents with or without website URLs and
            train your custom chatbot to answer any questions related to it.
          </Foot>
        </Grid>
      </Stack>
    </RootStyle>
  );
}

export default ReviewSection;
