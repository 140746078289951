import React, { useEffect, useState } from "react";
import Footer from "../home/Footer";
import AdminHeader from "../../layout/user header";
import { Box, Stack, Tab, Tabs, styled } from "@mui/material";
import InfoSection from "./InfoSection";
import PaymentSection from "./PaymentSection";
import HistorySection from "./HistorySection";
import { useLocation } from "react-router-dom";
import ReactGA from "../../Analytics";
import Feedback from "../home/Feedback";

const RootStyle = styled(Box)({
  background: "linear-gradient(90deg, #186ED0 0%, #17CEAD 100%)",
  padding: "2% 5%",
  minHeight: "100vh",
});

const StyledTab = styled(Tab)({
  backgroundColor: "rgba(255, 255, 255, 0.05)",
  fontFamily: "Raleway",
  textTransform: "none",
  fontSize: "10.5px",
  fontWeight: 600,
  color: "#353535",
  borderRadius: "6px 0px 0px 6px",
});

function Profile() {
  const location = useLocation();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: "User Account",
    });
  }, []);

  return (
    <RootStyle>
      <AdminHeader />
      <Stack width="100%" justifyContent="center">
        <Tabs
          variant="standard"
          scrollButtons
          allowScrollButtonsMobile
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.02)",
            borderRadius: "6px",
            mt: 1,
          }}
          textColor="rgba(53, 53, 53, 1)"
          value={value}
          onChange={handleChange}
          centered
        >
          <StyledTab label="Basic Info" />
          <StyledTab label="Your Card" />
          <StyledTab label="Payment History" />
        </Tabs>
      </Stack>
      <Box sx={{ minHeight: "50vh" }}>
        <Stack alignItems="center" justifyContent="center">
          {value == 0 && <InfoSection />}
          {value == 1 && <PaymentSection />}
          {value == 2 && <HistorySection />}
        </Stack>
      </Box>
      <Footer />
      <Feedback />
    </RootStyle>
  );
}

export default Profile;
