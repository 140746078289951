import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Repo from "../../repo/Repo";
import Toast from "../../components/Toast";

const initialState = {
  loading: false,
  error: "",
  user: "",
  isLoggedIn: "",
  userExist: false,
  leftMsgs: 0,
};

export const getUser = createAsyncThunk("user/fetchUser", (arg) => {
  return Repo.user({
    request: {
      method: "getUser",
      data: arg
        ? {
            _id: localStorage.getItem("email"),
            googleLogin: true,
          }
        : {
            _id: localStorage.getItem("email"),
          },
    },
  })
    .then(({ data }) => data.response.data.userObj)
    .catch((err) => console.log(err));
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userAdd: (state, action) => {
      state.user = action.payload;
    },
    userLogged: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    reduceMessage: (state) => {
      let messages = state?.user?.subscription?.messages;
      if (messages > 0) {
        state.user.subscription.messages = parseInt(messages) - 1;
      }
    },
    clearUser: (state, action) => {
      state.isLoggedIn = "";
      state.user = "";
      state.userExist = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      if (action?.payload?.email) {
        state.user = action.payload;
        if (action?.payload?.role) {
          state.isLoggedIn = action.payload.role;
        } else {
          state.isLoggedIn = "user";
        }
      } else {
        state.user = "";
        state.isLoggedIn = "";
        Toast("info", "Login Failed");
        localStorage.removeItem("loggedIn");
        localStorage.removeItem("email");
      }
      state.loading = false;
      state.error = "";
      state.userExist = true;
    });
    builder.addCase(getUser.rejected, (state, action) => {
      state.userExist = false;
      state.loading = false;
      state.user = "";
      state.error = action.error.message;
    });
  },
});

export const { userAdd, userLogged, clearUser, loadUser, reduceMessage } =
  userSlice.actions;

export default userSlice.reducer;
