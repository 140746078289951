import React, { useEffect } from "react";
import { Verified } from "@mui/icons-material";
import { Box, Stack, Typography, styled } from "@mui/material";
import MasterCard from "../../assets/images/mastercard icon.png";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ReactGA from "../../Analytics";

const RootStyle = styled(Box)(({ theme }) => ({
  marginTop: "50px",
  minHeight: "256px",
  width: "55%",
  background: "#FFFFFF33",
  padding: "20px 30px",
  borderRadius: "8px",
  [theme.breakpoints.down("md")]: {
    width: "95%",
  },
}));

function PaymentSection() {
  const { user } = useSelector((state) => state.user);

  const location = useLocation();
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: "Card Details",
    });
  }, []);

  let month = user?.cardDetails?.expMonth;
  if (user?.cardDetails?.expMonth < 10) {
    month = 0 + "" + user?.cardDetails?.expMonth;
  }

  return (
    <RootStyle>
      <Stack spacing={5}>
        <Box
          sx={{
            padding: "15px 20px",
            background: "#3535351A",
            minHeight: "109px",
            borderRadius: "6px",
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="row" spacing={1}>
              <Verified sx={{ color: "#11D6AA" }} />
              <img width="30px" src={MasterCard} alt="" />
              <Typography
                sx={{
                  fontFamily: "Raleway",
                  fontSize: "16px",
                  fontWeight: 700,
                }}
              >
                Credit Card
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <LockOutlinedIcon sx={{ fontSize: "18px", color: "#353535" }} />

                <Typography
                  sx={{
                    fontFamily: "Raleway",
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "#353535",
                  }}
                >
                  Secure Payment
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 2,
              minHeight: "38px",
              background: "#FFFFFF1A",
              p: "10px 15px",
            }}
          >
            <Stack direction="row" spacing={2}>
              <CreditCardIcon sx={{ color: "#35353580" }} />
              <Stack direction="row">
                <Typography> ********</Typography>
                <Typography
                  sx={{
                    fontFamily: "Raleway",
                    fontSize: "14px",
                    fontWeight: 600,
                    color: "#353535",
                  }}
                >
                  {user?.cardDetails?.last4}
                </Typography>
              </Stack>
            </Stack>
            <Typography
              sx={{
                fontFamily: "Raleway",
                fontSize: "14px",
                fontWeight: 500,
                color: "#353535",
              }}
            >
              Expires: {month}/
              {user.cardDetails?.expYear?.toString().slice(2, 4)}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            padding: "15px 20px",
            background: "#3535351A",
            minHeight: "49px",
            borderRadius: "6px",
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={3}
          >
            <Verified sx={{ color: "#11D6AA" }} />

            <Typography
              sx={{
                fontFamily: "Raleway",
                fontSize: "16px",
                fontWeight: 400,
                color: "#353535",
              }}
            >
              ${user?.subscription?.price}/month
            </Typography>
          </Stack>
        </Box>
      </Stack>
    </RootStyle>
  );
}

export default PaymentSection;
