import React from "react";
import { Box, Stack, styled } from "@mui/material";
import { Typography, Grid } from "@mui/material";
import ContactSupportOutlinedIcon from "@mui/icons-material/ContactSupportOutlined";

const RootStyle = styled(Box)({
  background: "linear-gradient(90deg, #186ED0 0%, #17CEAD 100%)",

  // minHeight: "700px",
  padding: "5%",
});
const Para = styled(Typography)({
  marginTop: "30px",
  color: "#353535",
});
function FAQs() {
  return (
    <RootStyle>
      <Box
        sx={{
          display: "flex",
          flexDirection: { sm: "column", xs: "column", md: "row" },
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            color: "white",
            fontSize: { md: "40px", xs: "24px" },
            fontWeight: 900,
            mb: 3,
          }}
        >
          FAQ
          <span style={{ color: "#353535" }}>s </span>
        </Typography>
      </Box>
      <Grid container spacing={{ md: 8, xs: 1 }} justifyContent="center">
        <Grid item md={5} xs={12}>
          <Box
            sx={{
              background: "rgba(255, 255, 255, 0.1)",
              borderRadius: "12px",
              padding: "5%",
              backdropFilter: "blur(12px)",
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h6">Is there a free plan ?</Typography>
              <ContactSupportOutlinedIcon />
            </Stack>
            <Para>
              Yes, after signup you get 1 chatbot, up to 30 messages and 5,000
              character limit. Use this demo to see how everything works.
            </Para>
          </Box>
        </Grid>
        <Grid item md={5} xs={12}>
          <Box
            sx={{
              background: "rgba(255, 255, 255, 0.1)",
              borderRadius: "12px",
              padding: "5%",
              backdropFilter: "blur(12px)",
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h6">
                What is meant by one chatbot ?
              </Typography>
              <ContactSupportOutlinedIcon />
            </Stack>
            <Para>
              One chatbot contains specific data and can answer any question
              about that data. Even if it was created using multiple documents
              and website data.
            </Para>
          </Box>
        </Grid>
        <Grid item md={5} xs={12}>
          <Box
            sx={{
              background: "rgba(255, 255, 255, 0.1)",
              borderRadius: "12px",
              padding: "5%",
              backdropFilter: "blur(12px)",
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h6">
                What is the user limit on my chatbot ?
              </Typography>
              <ContactSupportOutlinedIcon />
            </Stack>
            <Para>
              If you use your chatbot as a widget on your website and make it
              public, then anyone with the website link will be able to access
              it unless you make it private.
            </Para>
          </Box>
        </Grid>
        <Grid item md={5} xs={12}>
          <Box
            sx={{
              background: "rgba(255, 255, 255, 0.1)",
              borderRadius: "12px",
              padding: "5%",
              backdropFilter: "blur(12px)",
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h6">
                Can I upload multiple documents ?
              </Typography>
              <ContactSupportOutlinedIcon />
            </Stack>
            <Para>
              Yes, if you have a paid plan you will be able to upload multiple
              documents to one chatbot. For now only ‘PDF’ format is available
              but we’ll add more document formats soon.
            </Para>
          </Box>
        </Grid>
        <Grid item md={5} xs={12}>
          <Box
            sx={{
              background: "rgba(255, 255, 255, 0.1)",
              borderRadius: "12px",
              padding: "5%",
              backdropFilter: "blur(12px)",
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h6">
                How can I see my file character limit ?
              </Typography>
              <ContactSupportOutlinedIcon />
            </Stack>
            <Para>
              Once your file is attached for upload, you’ll get to see character
              count of attached files and also get notified when limit is
              reached.
            </Para>
          </Box>
        </Grid>
        <Grid item md={5} xs={12}>
          <Box
            sx={{
              background: "rgba(255, 255, 255, 0.1)",
              borderRadius: "12px",
              padding: "5%",
              backdropFilter: "blur(12px)",
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h6">
                How much time it takes to train chatbot ?
              </Typography>
              <ContactSupportOutlinedIcon />
            </Stack>
            <Para>
              training time depends on the amount of data fed to one chatbot. If
              there are multiple files and website link, then it can take up to
              1-2 minutes.
            </Para>
          </Box>
        </Grid>
      </Grid>
    </RootStyle>
  );
}

export default FAQs;
