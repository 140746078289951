import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import Emergency from "../../../assets/images/emergency.png";
import Toast from "../../../components/Toast";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function Delete() {
  const { chatbot } = useSelector((state) => state.chatbot);
  const navigate = useNavigate();

  const handleDelete = () => {
    if (!chatbot._id) {
      Toast("info", "Kindly Select a Chatbot");
      return;
    }
    var requestOptions = {
      method: "DELETE",
      redirect: "follow",
    };

    fetch(
      `https://api.urhja.com/delete_chatbot?botId=${chatbot._id}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        let res = JSON.parse(result);
        if (res.response == "Deleted") {
          Toast("success", res.response);
          navigate("/chatbots");
        } else {
          Toast("error", res.error);
        }
      })
      .catch((error) => {
        console.log("error", error);
        Toast("error", "Error");
      });
  };
  return (
    <div>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        direction="column"
        rowGap={4}
      >
        <img src={Emergency} alt="" height="40px" width="40px" />
        <Typography sx={{ fontSize: { md: "40px", xs: "18px" } }}>
          Delete Chatbot
        </Typography>
        <Typography
          variant="p"
          sx={{ fontSize: { md: "16px", xs: "12px" }, width: { md: "400px" } }}
        >
          Are you sure you want to delete your chatbot ? This action cannot be
          undone and would not reset used message credits.
        </Typography>
        <Button
          sx={{
            background: "rgba(203, 76, 76, 1) !important",
            color: "#FFF",
            textTransform: "capitalize",
            width: { md: "250px" },
          }}
          onClick={handleDelete}
        >
          Delete Chatbot
        </Button>
      </Grid>
    </div>
  );
}

export default Delete;
