import React, { useState } from "react";
import { Box, Button, Checkbox, Divider, InputAdornment } from "@mui/material";
import { LinearProgress, TextField, Stack, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useNavigate } from "react-router-dom";
import Repo from "../../repo/Repo";
import Toast from "../../components/Toast";
import { Title } from "./styles";
import MyTextField from "./MyTextField";

function Drive() {
  const [link, setLink] = useState("");
  const [linksProgress, setLinksProgress] = useState(1);
  const [linksLoading, setLinksLoading] = useState(false);

  const handleInput = (e) => {
    const { value } = e.target;
    setLink(value);
  };

  const getSubtitles = async () => {
    let { data } = await Repo.script({
      request: {
        method: "getSubtitles",
        data: {
          url: link,
        },
      },
    });
    console.log("data:", data);
  };

  const filtered = () => {
    // if (link.length == 0) {
    //   return selected;
    // } else {
    //   return urls;
    // }
    return [];
  };

  return (
    <Stack mt={3} justifyContent="center" alignItems="center" spacing={2}>
      <Title>Add Drive Link</Title>

      <Stack width="100%">
        <MyTextField
          placeholder="drive link"
          title="procceed"
          onChange={handleInput}
          onClick={getSubtitles}
          value={link}
        />

        <Box sx={{ height: "10px", mb: 1, width: "100%" }}>
          {linksLoading && (
            <>
              <Typography variant="body2" color="text.secondary">{`${Math.round(
                linksProgress
              )}%`}</Typography>
              <Box
                sx={{
                  height: "6px",
                  background: "rgba(242, 242, 242, 0.2)",
                }}
              >
                <LinearProgress
                  sx={{ height: "6px" }}
                  variant="determinate"
                  value={linksProgress}
                />
              </Box>
            </>
          )}
        </Box>
      </Stack>

      {/* <Stack width="100%" maxHeight="350px" overflow="auto" spacing={0.5}>
        {filtered() &&
          filtered().map((item, i) => {
            return (
              <Box key={i} sx={{ display: "flex" }}>
                <Checkbox
                  disabled={loading ? true : false}
                  checked={found[item] ? true : false}
                  onChange={() => handleSelect(item)}
                  inputProps={{ "aria-label": "controlled" }}
                  sx={{
                    "&.Mui-checked": {
                      color: "white",
                    },
                  }}
                />
                <Box
                  sx={{
                    background: "rgba(255, 255, 255, 0.05)",
                    borderRadius: "6px",
                    p: "5px",
                    pt: "10px",
                    width: "89%",
                    display: "block",
                    alignItems: "center",
                    wordWrap: "break-word",
                    cursor: "pointer",
                  }}
                  onClick={() => handleSelect(item)}
                >
                  <Typography>{item}</Typography>
                </Box>
              </Box>
            );
          })}
      </Stack> */}
    </Stack>
  );
}

export default Drive;
