import { Button, styled } from "@mui/material";

export const ButtonFAQs = styled(Button)({
  height: "27px",
  textTransform: "none",
  background: "rgba(33, 181, 190, 1) !important",
  color: "#FFF",
});
export const ButtonPricing = styled(Button)({
  height: "27px",
  textTransform: "none",
  background: "rgba(31, 176, 133, 1) !important",
  color: "#FFF",
});
export const ButtonLogin = styled(Button)({
  textTransform: "none",
  height: "27px",
  color: "#FFF",
  background: "rgba(34, 106, 193, 1) !important",
});
