import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import unlimitedIcon from "../../../assets/images/unlimited icon.svg";
import DoneIcon from "@mui/icons-material/Done";
import { IconBox, RootStyle, SubTitle } from "./styles";
import { BtnSubscribed } from "../style";
import { useSelector } from "react-redux";

function Unlimited({ onClick }) {
  const { user } = useSelector((state) => state.user);

  return (
    <RootStyle>
      <Stack>
        <Stack
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
        >
          <IconBox>
            <img width="22px" height="22px" src={unlimitedIcon} alt="" />
          </IconBox>
          <Stack>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: 500,
                fontSize: "12px",
                color: "#C8C8C8",
                textAlign: "center",
              }}
            >
              PLAN TYPE
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                fontFamily: "Raleway",
                fontWeight: 700,
                fontSize: { xs: "16px", md: "20px" },
                color: "#353535",
              }}
            >
              Unlimited
            </Typography>
          </Stack>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontWeight: 600,
              fontSize: { xs: "16px", md: "20px" },
              color: "#FA944B",
            }}
          >
            $$$
          </Typography>
        </Stack>

        <Stack alignItems="center" justifyContent="center">
          <Typography
            sx={{
              fontFamily: "Urbanist",
              fontWeight: 600,
              fontSize: "13px",
              color: "#FA944B",
            }}
          >
            Custom plan - billed monthly
          </Typography>
        </Stack>

        <Stack mt={1} spacing={0.5}>
          <Stack direction="row" spacing={1}>
            <DoneIcon sx={{ color: "#FA944B" }} />
            <SubTitle sx={{ fontSize: "13px", color: "#828282" }}>
              50,000
              <span style={{ marginLeft: "5px", fontWeight: 500 }}>
                message credits /month ( Messages over the limit will use your
                OpenAI API Key ).
              </span>
            </SubTitle>
          </Stack>
          <Stack direction="row" spacing={1}>
            <DoneIcon sx={{ color: "#FA944B" }} />
            <SubTitle sx={{ fontSize: "13px", color: "#828282" }}>
              Unlimited
              <span style={{ marginLeft: "5px", fontWeight: 500 }}>
                chatbots.
              </span>
            </SubTitle>
          </Stack>
          <Stack direction="row" spacing={1}>
            <DoneIcon sx={{ color: "#FA944B" }} />
            <SubTitle sx={{ fontSize: "13px", color: "#828282" }}>
              12,000,000
              <span style={{ marginLeft: "5px", fontWeight: 500 }}>
                characters / chatbot.
              </span>
            </SubTitle>
          </Stack>
          <Stack direction="row" spacing={1}>
            <DoneIcon sx={{ color: "#FA944B" }} />
            <SubTitle sx={{ fontSize: "13px", color: "#828282" }}>
              Embed
              <span style={{ marginLeft: "5px", fontWeight: 500 }}>
                on unlimited websites.
              </span>
            </SubTitle>
          </Stack>
          <Stack direction="row" spacing={1}>
            <DoneIcon sx={{ color: "#FA944B" }} />
            <SubTitle sx={{ fontSize: "13px", color: "#828282" }}>
              Upload
              <span style={{ marginLeft: "5px", fontWeight: 500 }}>
                up to 50 files.
              </span>
            </SubTitle>
          </Stack>
          <Stack direction="row" spacing={1}>
            <DoneIcon sx={{ color: "#FA944B" }} />
            <SubTitle sx={{ fontSize: "13px", color: "#828282" }}>
              API
              <span style={{ marginLeft: "5px", fontWeight: 500 }}>Access</span>
            </SubTitle>
          </Stack>
          <Stack direction="row" spacing={1}>
            <DoneIcon sx={{ color: "#FA944B" }} />
            <SubTitle sx={{ fontSize: "13px", color: "#828282" }}>
              Remove
              <span style={{ marginLeft: "5px", fontWeight: 500 }}>
                “Powered by Talk It Out”.
              </span>
            </SubTitle>
          </Stack>
          <Stack direction="row" spacing={1}>
            <DoneIcon sx={{ color: "#FA944B" }} />
            <SubTitle sx={{ fontSize: "13px", color: "#828282" }}>
              Option to choose
              <span style={{ margin: "0 5px", fontWeight: 700 }}>GPT-4</span>
              or
              <span style={{ margin: "0 5px", fontWeight: 700 }}>Claude</span>
              or
              <span style={{ margin: "0 5px", fontWeight: 700 }}>Bard</span>
              model ( upcoming ).
            </SubTitle>
          </Stack>
          <Stack direction="row" spacing={1}>
            <DoneIcon sx={{ color: "#FA944B" }} />
            <SubTitle sx={{ fontSize: "13px", color: "#828282" }}>
              Option to
              <span style={{ margin: "0 5px", fontWeight: 700 }}>
                fine-tune
              </span>
              the model and have your own
              <span style={{ margin: "0 5px", fontWeight: 700 }}>
                customised Large Language Model.
              </span>
            </SubTitle>
          </Stack>
        </Stack>
      </Stack>
      <Stack mt={1} justifyContent="center" alignItems="center">
        {user?.subscription?.subType === "unlimited" ? (
          <BtnSubscribed fullWidth>Subscribed</BtnSubscribed>
        ) : (
          <Button
            fullWidth
            size="small"
            variant="contained"
            sx={{
              background: "#FFF1E8 !important",
              border: "1px solid #FA944B",
              borderRadius: "6px",
              textTransform: "none",
              color: "#FA944B",
            }}
            onClick={onClick}
          >
            Contact Us
          </Button>
        )}
      </Stack>
    </RootStyle>
  );
}

export default Unlimited;
