import React, { useState } from "react";
import { Typography, Box, Button, Grid } from "@mui/material";
import CopyButton from "./CopyBtn";
import { useSelector } from "react-redux";

function Embed() {
  const { chatbot } = useSelector((state) => state.chatbot);
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal((prev) => !prev);
  };

  let textToCopy = ` <iframe
  src="https://talkitout.ai/chat/${chatbot._id}" 
  width="100%"
  height="700"
  frameborder="0"></iframe> `;
  let scripts = ` <div style="position: fixed; bottom: 5; right: 10; width: 100%; z-index: 1">
  <embed
    type="text/html"
    src="https://talkitout.ai/embed-chat/${chatbot._id}"
    width="100%"
    height="650"
  />
</div>`;

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          variant="contained"
          onClick={toggle}
          sx={{
            my: 2,
            color: "#353535",
            textTransform: "none",
            background: "rgba(255, 255, 255, 0.1) !important",
          }}
        >
          Generate Embed Code
        </Button>
      </Box>
      <Box
        sx={{
          minHeight: "57.8vh",
          margin: "0 auto",
          width: { xs: "100%", md: "55%" },
        }}
      >
        {modal && (
          <Grid container spacing={4}>
            <Grid item md={6} xs={12}>
              <Typography variant="h6" sx={{ mt: 2, textAlign: "center" }}>
                Embed iframe
              </Typography>
              <Typography
                sx={{
                  fontSize: { md: "14px", xs: "12px" },
                  color: "rgba(53, 53, 53, 1)",
                  lineBreak: "auto",
                  display: "inline-block",
                  my: 2,
                  textAlign: "center",
                }}
              >
                Add the chatbot anywhere on your website by adding this iframe
                to your html code
              </Typography>
              {chatbot._id && (
                <Box
                  sx={{
                    background: "rgba(255, 255, 255, 0.1)",
                    borderRadius: "6px",
                    padding: "10px",
                    minHeight: { xs: "150px", md: "300px" },
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: { md: "18px", xs: "11px" },
                      wordWrap: "break-word",
                    }}
                  >
                    {textToCopy}
                  </Typography>
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <CopyButton text={textToCopy} />
                  </Box>
                </Box>
              )}
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography variant="h6" sx={{ textAlign: "center", mt: 2 }}>
                Add chat bubble
              </Typography>
              <Typography
                sx={{
                  fontSize: { md: "14px", xs: "12px" },
                  color: "rgba(53, 53, 53, 1)",
                  lineBreak: "auto",
                  display: "inline-block",
                  my: 2,
                  textAlign: "center",
                }}
              >
                Add a chat bubble to the bottom right of your website by adding
                this code to your html head.
              </Typography>
              {chatbot._id && (
                <Box
                  sx={{
                    background: "rgba(255, 255, 255, 0.1)",
                    borderRadius: "6px",
                    padding: "10px",
                    minHeight: { xs: "150px", md: "300px" },
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: { md: "18px", xs: "11px" },
                      wordWrap: "break-word",
                    }}
                  >
                    {scripts}
                  </Typography>
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <CopyButton text={scripts} />
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
}

export default Embed;
