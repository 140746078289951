import React from "react";
import { Box, Stack } from "@mui/material";
import { Button, Dialog, DialogContent, Typography } from "@mui/material";

function LimitWarning({ info, onClose }) {
  return (
    <Dialog open={info.modal} onClose={onClose} fullWidth maxWidth="xs">
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant="h6" sx={{ color: "red", textAlign: "center" }}>
            Characters Limit Exceeds!
          </Typography>
          <Typography sx={{ textAlign: "center" }}>
            Current Characters : {info.currentChars}
          </Typography>
          <Typography sx={{ textAlign: "center" }}>
            Left Characters : {info.leftChars}
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="outlined"
              sx={{ textTransform: "none" }}
              onClick={onClose}
            >
              Close
            </Button>
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default LimitWarning;
