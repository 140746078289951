import React, { useEffect, useState } from "react";
import { Box, Button, Paper, Dialog } from "@mui/material";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import { TableContainer, TableHead, tableCellClasses } from "@mui/material";
import { DialogContent, Stack, styled, Typography } from "@mui/material";
import DashboarHead from "../../layout/admin header";
import DataTable from "react-data-table-component";
import Repo from "../../repo/Repo";
import moment from "moment";
import TailSpinLoader from "../../components/TailSpinLoader";
import ReactGA from "../../Analytics";
import { useLocation } from "react-router-dom";
import Toast from "../../components/Toast";

const RootStyle = styled(Box)({
  background: "linear-gradient(90deg, #186ED0 0%, #17CEAD 100%)",
  padding: "2% 5%",
  minHeight: "100vh",
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1FB085",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const customStyles = {
  header: {
    style: {
      color: "#1FB085",
    },
  },
  headCells: {
    style: {
      backgroundColor: "#1FB085",
      color: "white",
      fontSize: "14px",
      fontWeight: 700,
      fontFamily: "Raleway",
    },
  },
  cells: {
    style: {
      paddingLeft: "18px",
    },
  },
};

function Dashboard() {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [fbLoading, setfbLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [records, setRecords] = useState([]);

  const columns = [
    {
      name: "#",
      width: "60px",
      selector: (row, i) => i + 1,
    },
    {
      name: "User Emails",
      selector: (row) => row?.email,
    },
    {
      name: "Total Chatbots",
      selector: (row) => row?.subscription?.botCount,
    },
    {
      name: "Subscription Type",
      selector: (row) => row?.subscription?.subType,
    },
    {
      name: "Message Limit (rem)",
      selector: (row) => row?.subscription?.messages,
    },
    {
      name: "Character limit (rem)",
      selector: (row) => row?.subscription?.charLimit,
    },
    {
      name: "Registration Date",
      selector: (row) => {
        return moment.unix(row.created_at).format("DD-MM-YYYY hh:mm A");
      },
    },
    {
      name: "Feedback",
      cell: (row) => (
        <Button
          size="small"
          variant="contained"
          sx={{ textTransform: "none" }}
          onClick={() => getFeedback(row.email)}
        >
          View
        </Button>
      ),
    },
  ];

  useEffect(() => {
    getUsers();

    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: "Admin Dashbaord",
    });
  }, []);

  const getFeedback = async (email) => {
    toggle();
    setRecords([]);
    setfbLoading(true);
    let { data } = await Repo.feedback({
      request: {
        method: "getFeedbacks",
        data: {
          email: email,
        },
      },
    });
    setfbLoading(false);
    if (data.response.status.statusCode == 200) {
      setRecords(data.response.data.feedbacksArr);
    } else if (data.response.status.statusCode == 404) {
      Toast("info", "User has no feedback");
    } else {
      Toast("error", "Error");
    }
  };
  const toggle = () => {
    setOpen((prev) => !prev);
  };
  const getUsers = async () => {
    try {
      setLoading(true);
      let { data } = await Repo.user({
        request: {
          method: "getAllUsers",
        },
      });
      if (data.response.status.statusCode == 200) {
        setUsers(data.response.data.usersArr);
        setLoading(false);
      } else {
        setLoading(false);
        setUsers([]);
      }
    } catch (err) {
      console.log("err:", err);
    }
  };
  return (
    <RootStyle>
      <DashboarHead />
      <Box sx={{ my: 5 }}>
        {loading && (
          <Stack alignItems="center" justifyContent="center">
            <TailSpinLoader />
          </Stack>
        )}
        {!loading && (
          <DataTable
            columns={columns}
            data={users}
            pointerOnHover
            customStyles={customStyles}
          />
        )}
      </Box>
      <Dialog open={open} onClose={toggle} fullWidth maxWidth="md">
        <DialogContent>
          <Typography variant="h5" sx={{ mb: 2, textAlign: "center" }}>
            User Feedbacks
          </Typography>
          {fbLoading && <TailSpinLoader />}
          {!fbLoading && (
            <Stack>
              {records && records.length > 0 ? (
                <TableContainer component={Paper} sx={{ mb: 2 }}>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Message</StyledTableCell>
                        <StyledTableCell sx={{ width: "100px" }}>
                          Rating
                        </StyledTableCell>
                        <StyledTableCell sx={{ width: "150px" }}>
                          Date
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {records &&
                        records.map((row, i) => (
                          <TableRow key={i}>
                            <StyledTableCell component="th" scope="row">
                              {row.message}
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              {row.rating}
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              {row.date}
                            </StyledTableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography variant="h6" sx={{ mb: 2, textAlign: "center" }}>
                  No records
                </Typography>
              )}
              <Stack alignItems="end" justifyContent="flex-end">
                <Button
                  size="small"
                  variant="outlined"
                  sx={{ textTransform: "none" }}
                  onClick={toggle}
                >
                  Close
                </Button>
              </Stack>
            </Stack>
          )}
        </DialogContent>
      </Dialog>
    </RootStyle>
  );
}

export default Dashboard;
